export type SizeProps = 'lg' | 'md' | 'sm' | 'xs';
export type suffixIcon = 'search' | 'add' | 'goBack' | 'update';

import React, { CSSProperties, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

export type ColorProps =
	| 'primary'
	| 'info'
	| 'success'
	| 'warning'
	| 'error'
	| 'secondary'
	| 'accent'
	| 'ghost'
	| 'outline'
	| 'default';

interface ICommonProps {
	text?: string;
	color?: ColorProps;
	className?: string;
	disabled?: boolean;
	size?: SizeProps;
	label?: string;
	id?: string;
	style?: React.CSSProperties;
	validationMessage?: JSX.Element;
}

export interface IButtonUIProps extends ICommonProps {
	htmlType?: 'button' | 'submit';
	outline?: boolean;
	wide?: boolean;
	block?: boolean;
	isSticky?: boolean;
	direction?: 'bottom' | 'top';
	shape?: 'square' | 'circle';
	loading?: boolean;
	animation?: boolean;
	btnactive?: boolean;
	radius?: boolean;
	suffixIcon?: suffixIcon;
	onClick?: () => void;
}

export interface ErrorsMessageProps<T> {
	className?: string;
	errors?: Partial<DeepMap<T, FieldError>>;
	name: string;
	messageExtra?: string;
}

export const sizeMap: { [key in InputSize]: string } = {
	small: 'p-2 text-sm',
	medium: 'p-4 text-sm',
	onlyborderBottom: 'px-3 pt-6 pb-2 text-sm',
	base: 'py-[16px] px-0 text-base',
	large: 'p-5 text-lg',
};

export interface TypeStyleForms {
	labelInsideBorder?: boolean;
	OnlyborderBottom?: boolean;
	animation?: boolean;
	placeholder?: boolean;
}

export type labelColor = CSSProperties;
export type InputSize = 'base' | 'large' | 'medium' | 'onlyborderBottom' | 'small';
export type InputType = 'text' | 'email' | 'date' | 'tel' | 'checkbox' | 'password';

export interface Styles {
	label?: string;
	input?: string;
}

//for input normal
export type InputProps = {
	id: string;
	label?: string;
	type?: InputType;
	size?: InputSize;
	className?: string;
	labelColor?: labelColor;
	style?: Styles;
	suffixIcon?: JSX.Element;
	validationMessage?: JSX.Element;
	typeStyleForms: TypeStyleForms;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size'>;

export interface SelectOptions {
	name: string;
	value: string | number;
}

export type SelectProps = {
	id: string;
	label?: string;
	className?: string;
	classNameSelect?: string;
	typeStyleForms?: TypeStyleForms;
	validationMessage?: JSX.Element;
	labelColor?: labelColor;
	render?: JSX.Element;
	size?: InputSize;
	options: SelectOptions[];
	isAvatar?: boolean;
	suffixIcon?: JSX.Element;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'size'>;
