import { useAddToCart, useAppDispatch } from '@/hooks';
import { IProductItem } from '@/models';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Notification from '../Notification';

interface IIconAddCartNow {
	data: any;
	onClickCart?: () => void;
	// data: IProductItem;
}

const IconAddCartNow: React.FC<IIconAddCartNow> = ({ data, onClickCart }) => {
	const dispatch = useAppDispatch();
	const { handleAddToCart } = useAddToCart(dispatch);

	return (
		<div className='absolute bg-white cursor-pointer xs:drop-shadow-lg md:drop-shadow-none hover:shadow-shadowIconCartProduct active:bg-primary group rounded-[24px] top-[18px] right-[6px] z-50 transform -translate-x-0 -translate-y-4'>
			<ShoppingCartIcon
				width={35}
				className='text-primary p-2 group-active:text-base-100'
				onClick={() => {
					onClickCart?.();
				}}
			/>
		</div>
	);
};

export default IconAddCartNow;
