/* eslint-disable */
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/constant';
import Cookies from 'js-cookie';
import React from 'react';
interface MyProps {
	children: React.ReactNode;
}

interface MyState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<MyProps, MyState> {
	constructor(props: any) {
		super(props);

		// Define a state variable to track whether is an error or not
		this.state = { hasError: false };
	}
	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI

		return { hasError: true };
	}
	componentDidCatch(error: any, errorInfo: any) {
		// You can use your own error logging service here
		console.log({ error, errorInfo });
	}
	render() {
		// Check if the error is thrown
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className='flex flex-col items-center justify-center h-screen bg-gray-100'>
					<h1 className='text-3xl font-bold text-red-500 mb-4'>Úi !</h1>
					<p className='text-lg text-gray-700 mb-8'>Có gì đó không đúng, xin vui lòng thử lại.</p>
					<button
						className='bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded'
						onClick={() => {
							this.setState({ hasError: false });
							localStorage.clear();
							Cookies.remove(ACCESS_TOKEN);
							Cookies.remove(REFRESH_TOKEN);
							window.location.reload();
						}}
					>
						Tải lại trang
					</button>
				</div>
			);
		}

		// Return children components in case of no error

		return this.props.children;
	}
}

export default ErrorBoundary;
