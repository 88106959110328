import { IParamChangeVariationCart, IParamCreateOrder, IParamsAddToCart } from '@/models';
import { request } from '@/utils';
import { AxiosRequestConfig } from 'axios';

export const getInfoCart = (options: AxiosRequestConfig): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/cart',
		method: 'GET',
		...options,
	});
};

export const addToCart = (data: IParamsAddToCart): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/cart',
		method: 'PUT',
		data,
	});
};

export const changeVariationsCart = (data: IParamChangeVariationCart): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/cart/change_product_variation',
		method: 'PUT',
		data,
	});
};

export const createOrder = (data: IParamCreateOrder): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/order/create',
		method: 'POST',
		data,
	});
};
