import classNames from 'classnames';
import React from 'react';

interface ITagDiscount {
	className?: string;
	percent?: number;
	base?: boolean;
}

const TagDiscount: React.FC<ITagDiscount> = ({ className, percent, base }) => {
	return (
		<div
			className={classNames([
				'text-[#EB5757] bg-[#FFF0E9] p-1 flex justify-center items-center rounded',
				base ? 'text-base' : ' text-medium',
				className,
			])}
		>
			-{percent}%
		</div>
	);
};

export default TagDiscount;
