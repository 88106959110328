import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { FC } from 'react';

import { ErrorsMessageProps } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
const Messages: FC<ErrorsMessageProps<{}>> = ({ name, className, errors, messageExtra }) => {
	return (
		<ErrorMessage
			errors={errors}
			name={name}
			render={({ message }) => (
				<p className={classNames(['text-small text-left block text-red-custom', className])}>
					{messageExtra ?? message}
				</p>
			)}
		/>
	);
};

export default Messages;
