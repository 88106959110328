import React from 'react';
import IconAddCartNow from './IconAddCartNow';
import { useRouter } from 'next/router';
import ImageCustom from '@/components/ImageCustom';
import { TagDiscount } from '../Tag';
import { useCart } from '@/modules/shared/hooks';
import classNames from 'classnames';

export interface ProductCardProps {
	// ref?: any;
	className?: string;
	isCenter?: boolean;
	name?: string;
	title: string;
	image: string;
	url: string;
	price: number;
	split?: number;
	onClick?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
	// ref,
	className,
	isCenter,
	image,
	title,
	name,
	url,
	price,
	split,
	onClick,
}) => {
	const router = useRouter();

	return (
		<div
			className={classNames(
				'relative bg-base-100 rounded-[10px] shadow-preview-product overflow-hidden',
				className,
			)}
		>
			<IconAddCartNow data={{}} onClickCart={onClick} />
			<span
				className='group cursor-pointer w-full h-full'
				style={{ width: 'inherit' }}
				onClick={() => router.push(`/product-detail/[slug]`, url)}
			>
				<div className='w-full relative overflow-hidden'>
					<ImageCustom
						className={'aspect-square'}
						fill
						src={image}
						alt={name}
						objectFit='contain'
					/>
				</div>
				<div className='space-y-[11px] text-left p-[15px]'>
					<h6 className='text-[##333333] text-base line-clamp-2 font-bold'>{title}</h6>
					<div className={classNames('flex items-center gap-1', isCenter && 'justify-center')}>
						<div className='text-red-custom text-[16px] md:text-[16px] lg:text-[20px] font-bold'>
							{price.toLocaleString('it-IT')}₫
						</div>
						{split && split < 100 ? (
							<TagDiscount
								className='!text-[14px] lg:!text-base leading-[16px]'
								percent={100 - split}
								base
							/>
						) : null}
					</div>
				</div>
			</span>
		</div>
	);
};

export default ProductCard;
