import classNames from 'classnames';

type TypesuffixIcon = 'arrow' | 'plus';

const suffixTypeIcon: { [key in TypesuffixIcon]: string } = {
	arrow: 'collapse-arrow',
	plus: 'collapse-plus',
};

interface IRenderCollapse {
	title: string;
	children: React.ReactNode;
	suffixIconType?: TypesuffixIcon;
	classNameWrapper?: string;
	classNameTitle?: string;
	classNameContent?: string;
	defaultChecked?: boolean;
}

const Collapse: React.FC<IRenderCollapse> = ({
	children,
	suffixIconType = 'arrow',
	title,
	classNameWrapper,
	classNameContent,
	classNameTitle,
	defaultChecked = true,
}) => {
	return (
		<div
			className={classNames([
				'collapse pb-8 pt-6 border-b last-of-type:border-b-0 border-[#E0D8D1] w-full',
				classNameWrapper,
				suffixTypeIcon[suffixIconType],
			])}
		>
			<input type='checkbox' className='peer' defaultChecked={defaultChecked} />
			<div
				className={classNames([
					'collapse-title p-0 text-medium font-bold flex items-center',
					classNameTitle,
				])}
			>
				{title}
			</div>
			<div className={classNames(['collapse-content pl-0 font-bold', classNameContent])}>
				{children}
			</div>
		</div>
	);
};

export default Collapse;
