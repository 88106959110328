import { IParamGetFullOrderNonSubmit, IParamsGetOrdersWithId } from '@/models';
import { request } from '@/utils';

export const getAllOrderNonSubmit = (data: IParamGetFullOrderNonSubmit): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/order_all_user',
		method: 'POST',
		data,
	});
};

export const getOrderWithId = (data: IParamsGetOrdersWithId): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/order_by_phone_and_id',
		method: 'POST',
		data,
	});
};
