export enum ENUM_DEFAULT_PARAMS_PAGINATION {
	Page = 1,
	PageSize = 30,
}

export enum DeviceType {
	MOBILE = 'mobile',
	DESKTOP = 'desktop',
}

export enum TYPE_PRODUCT {
	NEW = 'NEW',
	POPULAR = 'POPULAR',
	DISCOUNT = 'DISCOUNT',
}

export enum TYPE_SEARCH {
	PRICE = 1,
	BRAND = 2,
}

export enum SEARCH_COLUMN {
	ASC = 0,
	DESC = 1,
}

export enum ORDER_STATUS {
	NEW = 1,
	SUCCESS = 2,
	FAILED = 3,
}

export const orderStatusText: { [key in ORDER_STATUS]: string } = {
	1: 'Đang xử lý',
	2: 'thành công',
	3: 'Thất bại',
};
