import { PayloadAction } from '@reduxjs/toolkit';

import { IParamsGetOrdersWithId, IPreviewOrderItem, ParamsRequestSaga } from '@/models';
import { IStateOrder } from '@/store/reducers/orderSlice';

export const handleOrderActions = {
	getOrderWithIdRequest(
		state: IStateOrder,
		_: PayloadAction<ParamsRequestSaga<IParamsGetOrdersWithId, IPreviewOrderItem>>,
	) {
		state.dataOrder.isRequestGetOrderWithId = true;
	},
	getOrderWithIdSuccess(state: IStateOrder, action: PayloadAction<IPreviewOrderItem>) {
		state.dataOrder.isRequestGetOrderWithId = false;
		state.dataOrder.dataOrderWithId = action.payload;
	},
	getOrderWithIdFailed(state: IStateOrder) {
		state.dataOrder.isRequestGetOrderWithId = false;
	},
};
