import '@/styles/globals.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'tailwindcss/tailwind.css';
// Import Swiper styles
import 'rc-slider/assets/index.css';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

// import 'swiper/css';
import 'swiper/css/grid';

import { BackToTop, ErrorBoundary } from '@/components';
import { ACCESS_TOKEN, ENUM_DEFAULT_PARAMS_PAGINATION } from '@/constant';
import { fetcher } from '@/hooks';
import { AppPropsWithLayout, ICartInfo } from '@/models';
import {
	fetchListBanner,
	fetchListBrand,
	fetchListCategory,
	fetchListCategoryTree,
	getInfoCart,
} from '@/services';
import { wrapper } from '@/store';
import { cartActions } from '@/store/reducers/cartSlice';
import { commonActions } from '@/store/reducers/commonSlice';
import { checkDeviceMobile } from '@/utils';
import Cookies from 'cookies';
import { NextPageContext } from 'next';
import App from 'next/app';
import { useRouter } from 'next/router';
import { Provider } from 'react-redux';
import { SWRConfig } from 'swr';

interface MyPageContext extends NextPageContext {
	ctx: NextPageContext;
}

const DEFAULT_REFRESH_INTERVAL = 60 * 60 * 1000;

const MyApp = ({ Component, ...rest }: AppPropsWithLayout) => {
	const { store, props } = wrapper.useWrappedStore(rest);
	const router = useRouter();
	const getLayout = Component.getLayout ?? ((page) => page);

	return (
		<Provider store={store}>
			<SWRConfig
				value={{
					revalidateIfStale: true,
					revalidateOnReconnect: true,
					revalidateOnFocus: false,
					revalidateOnMount: true,
					onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
						// Never retry on 404.
						if (error.status === 404) return;

						// Only retry up to 2 times.
						if (retryCount >= 2) return;

						// Retry after 500 mili seconds.
						setTimeout(() => revalidate({ retryCount }), 5000);
					},
					refreshInterval: DEFAULT_REFRESH_INTERVAL,
					fetcher,
					provider: () => new Map(),
				}}
			>
				<ErrorBoundary>
					{getLayout(<Component {...props.pageProps} key={router.asPath} />)}
				</ErrorBoundary>
				<BackToTop />
			</SWRConfig>
		</Provider>
	);
};

MyApp.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx: MyPageContext) => {
	try {
		const { req, res, err } = ctx.ctx;

		if (!req || !res) {
			return {
				pageProps: {
					error: err,
				},
			};
		}

		const cookies = new Cookies(req, res, { secure: process.env.NODE_ENV !== 'development' });

		const access_token = cookies.get(ACCESS_TOKEN);

		const appProps = await App.getInitialProps(ctx as any);

		const promises = [
			// fetchListBanner(),
			// getInfoCart({ headers: { Authorization: `Bearer ${access_token}` } }),
			fetchListCategory(),
			fetchListCategoryTree(),
			// fetchListBrand({ pageSize: 16, page: ENUM_DEFAULT_PARAMS_PAGINATION.Page }),
		];

		const results = await Promise.allSettled(promises);
		const [
			// responseBanner,
			// responseInitCart,
			responseCategory,
			responseCategoryTree,
			// responseBrand,
		] = results as any;

		// const TYPE_REPONSE_CART: ICartInfo = responseInitCart?.value;

		// console.log('chekc server, ', responseCategoryTree);

		// store.dispatch(commonActions.fetchListBannerSuccess(responseBanner?.value ?? []));
		// store.dispatch(commonActions.fetchListBrandSuccess(responseBrand?.value ?? []));
		store.dispatch(commonActions.fetchListCategorySuccess(responseCategory?.value ?? []));
		// store.dispatch(
		// 	cartActions.setQuantityCart(TYPE_REPONSE_CART?.data?.cart_info?.cart_items?.length ?? 0),
		// );
		// store.dispatch(
		// 	cartActions.setTotalPrice(+TYPE_REPONSE_CART?.data?.cart_info?.payment?.total ?? 0),
		// );
		store.dispatch(commonActions.fetchListCategoryTreeSuccess(responseCategoryTree?.value ?? []));

		store.dispatch({
			type: commonActions.setDeviceType,
			payload: checkDeviceMobile(req),
		});

		const defaultProps = {
			...appProps,
			deviceType: checkDeviceMobile(req),
			// dataBanner: responseBanner?.value [],
			dataCategory: responseCategory?.value,
			dataCategoryTree: responseCategoryTree?.value,
		};

		return {
			pageProps: {
				...defaultProps,
			},
		};
	} catch (error) {
		return {
			pageProps: {
				error,
			},
		};
	}
});

export default MyApp;
