interface ISkeletonLine {
	line?: number;
	nonLineMain?: boolean;
}

const SkeletonLine: React.FC<ISkeletonLine> = ({ line = 3, nonLineMain = false }) => {
	return (
		<div className='animate-pulse w-full'>
			{!nonLineMain ? (
				<div className='h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4'></div>
			) : null}

			{[...new Array(line)]?.map((_, i) => (
				<div
					key={i}
					className='h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5'
				></div>
			))}
		</div>
	);
};

export default SkeletonLine;
