import { ImageCustom, SkeletonImage, SkeletonLine } from '@/components';
import { TYPE_PRODUCT } from '@/constant';
import { useAppSelector } from '@/hooks';
import { IBrandItem, ListResponse } from '@/models';
import { SEPCIAL_CATEGORY } from '@/modules/home/constant';
import { commonSelectors } from '@/store/reducers/commonSlice';
import classNames from 'classnames';
import { useRouter } from 'next/router';

interface IProductBrandCard {
	className?: string;
	isMobile?: boolean;
}

const ProductBrandCard: React.FC<IProductBrandCard> = ({ className, isMobile }) => {
	const router = useRouter();

	const { dataCategory } = useAppSelector(commonSelectors);
	const listSpecialCategory = dataCategory.data?.data?.rows?.filter((item: any) => {
		return SEPCIAL_CATEGORY.includes(item.id);
	});

	if (isMobile) {
	}

	return (
		<div
			className={classNames('bg-base-100 rounded lg:rounded-lg p-4 lg:p-6 space-y-4', className)}
		>
			<h3 className='text-[#1F549D] text-[20px] lg:text-[24px] font-bold uppercase'>
				Ngành hàng nổi bật
			</h3>
			<div className='flex lg:flex-wrap -m-1 overflow-x-auto lg:overflow-x-hidden'>
				{listSpecialCategory
					? listSpecialCategory.map((el: any, index: any) => {
							return (
								<div key={index} className='shrink-0 p-1 w-[150px] lg:w-[12%]'>
									<div
										className='row-[1] cursor-pointer lg:row-auto bg-base-100 rounded-[26px] p-2 transition-all duration-100 hover:shadow-[14px_10px_12px_-10px_rgba(0,0,0,0.4)]'
										onClick={() => {
											router.push({
												pathname: '/san-pham',
												query: { category: el.id },
											});
										}}
									>
										<div className='w-full px-[20px]'>
											<img src={el.image ?? ''} className='w-full' />
										</div>
										<span className='text-primary text-[14px] xl:text-[16px] font-normal mt-[6px] block text-center line-clamp-2'>
											{el.name}
										</span>
									</div>
								</div>
							);
					  })
					: [...new Array(8)]?.map((_, i) => (
							<div
								className='row-[1] lg:row-auto bg-base-100 rounded-[26px] overflow-hidden p-2'
								key={i}
							>
								<div className={'aspect-square w-full rounded-small overflow-hidden'}>
									<SkeletonImage />
								</div>

								<div className='mt-2'>
									<SkeletonLine line={2} nonLineMain />
								</div>
							</div>
					  ))}
			</div>
		</div>
	);
};

export default ProductBrandCard;
