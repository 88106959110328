import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ImageCustom, SkeletonImage } from '@/components';
import { HomePageProps } from '@/models';

interface IBanner {
	dataBanner: Array<{ image: string }>;
}
const Banner: React.FC<IBanner> = ({ dataBanner }) => {
	return dataBanner?.length ? (
		<Swiper
			effect='fade'
			style={{ height: 'calc(100vh - 105px)' }}
			centeredSlides={true}
			autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}}
			pagination={{
				clickable: true,
			}}
			modules={[Autoplay, Pagination, EffectFade]}
			className='mySwiper banner-swipper xl:h-[500px] 2xl:!h-[700px]'
		>
			{dataBanner?.map((image, index) => (
				<SwiperSlide key={index}>
					{/* <ImageCustom fill className='!h-full' src={image.image} /> */}
					<img src={image.image} alt='' />
				</SwiperSlide>
			))}
		</Swiper>
	) : (
		<div style={{ height: 'calc(100vh - 105px)' }}>
			<SkeletonImage />
		</div>
	);
};

export default Banner;
