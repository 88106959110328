import React, { SVGProps } from 'react';

const Check = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
	<svg
		width='10'
		height='8'
		viewBox='0 0 10 8'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		{...props}
	>
		<path
			d='M1 4L4 7L9 1'
			stroke='currentColor'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M1 4L4 7L9 1'
			stroke='black'
			strokeOpacity='0.2'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Check;
