import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import last from 'lodash/last';
import { IQueryParamsCommon, ListResponse } from 'models';
import type { SWRInfiniteConfiguration, SWRInfiniteResponse } from 'swr/infinite';
import useSWRInfinite from 'swr/infinite';

import { ENUM_DEFAULT_PARAMS_PAGINATION } from '@/constant';
import { request } from '@/utils';

export type GetRequest = AxiosRequestConfig;

export type Modify<T, R> = Omit<T, keyof R> & R;

export type Merge<X, Y> = {
	[K in keyof X | keyof Y]: (K extends keyof X ? X[K] : never) | (K extends keyof Y ? Y[K] : never);
};

export type AxiosWithModifyResponse<T> = Merge<AxiosResponse<T>, ListResponse<T>>;

interface InfinityReturn<Data, Error>
	extends Pick<
		SWRInfiniteResponse<AxiosWithModifyResponse<Data>, AxiosError<Error>>,
		'isValidating' | 'mutate' | 'size' | 'setSize' | 'error'
	> {
	data: Data | undefined; // to be declar with every request axios
	response: ListResponse<Data> | undefined; //declared with unique data | data[] type
}

export const fetcher = (requestParams: GetRequest, params: IQueryParamsCommon) => {
	return request({
		url: requestParams?.url,
		method: requestParams?.method,
		params: params,
		baseURL: requestParams?.baseURL,
		// baseURL: requestParams?.baseURL,
	}).then((data) => data);
};

export interface AppSWRInfinityProps {
	request: GetRequest;
	query?: object | null;
}

const getKeyQueryString = (query: object) => {
	return Object.keys(query)
		.map((key) => `${key}=${query[key]}`)
		.join('&');
};

export const useAppSWRInfinity = <Data = unknown, Error = unknown>(
	keyRequest: AppSWRInfinityProps | null,
	configs: SWRInfiniteConfiguration<AxiosWithModifyResponse<Data>, AxiosError<Error>> = {},
	customReponse?: any,
): InfinityReturn<Data, Error> => {
	const {
		data: response,
		error,
		isValidating,
		mutate,
		size,
		setSize,
	} = useSWRInfinite<AxiosWithModifyResponse<Data> | any, AxiosError<Error>>(
		(index: number) => {
			const isQuery = keyRequest?.query;

			return keyRequest?.request.url
				? {
						url: `${keyRequest?.request?.url}${
							keyRequest?.request?.url?.includes('?') ? '&' : '?'
						}${
							isQuery
								? getKeyQueryString(isQuery)
								: `page=${index + 1}&size=${ENUM_DEFAULT_PARAMS_PAGINATION.PageSize}`
						}`,
						baseURL: keyRequest?.request?.baseURL,
						method: keyRequest?.request?.method,
				  }
				: null;
		},
		fetcher,
		configs,
	);

	const dataCurrent = last(response);

	const mergeArr = response?.reduce((curr, prev) => {
		return [...curr, ...(prev?.data.list ?? [])];
	}, []);

	return {
		data: mergeArr,
		response: dataCurrent,
		error,
		isValidating,
		mutate,
		size,
		setSize,
	};
};
