export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const INFO_ORDER_USER = 'info_order_user';

export const MESSSAGE_GLOBAL =
	'Lỗi hệ thống, vui lòng liên hệ số 0977991546 để được hỗ trợ kỹ thuật về vấn đề này';

export const REGEX_CHARACTER_SPECIAL = /^[^`~!@#$%^&*()_+={}\\[\]|\\//:"';“’<,>.?๐฿-]*$/;
export const REGEX_PHONE_NUMBER = /^(84|0[3|5|7|8|9])+([0-9]{8})$/g;
export const REGEX_EMAIL =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
