import { PayloadAction } from '@reduxjs/toolkit';

import { IBannerItem, IQueryParamsCommon, ParamsRequestSaga } from '@/models';
import { IState } from '@/store/reducers/commonSlice';

export const handleFetchListBanner = {
	fetchListBannerRequest(
		state: IState,
		_: PayloadAction<ParamsRequestSaga<Partial<IQueryParamsCommon>>>,
	) {
		state.dataBanner.isRequest = true;
	},
	fetchListBannerSuccess(state: IState, action: PayloadAction<IBannerItem>) {
		state.dataBanner.data = action.payload;
		state.dataBanner.isRequest = false;
	},
	fetchListBannerFailed(state: IState) {
		state.dataBanner.isRequest = false;
		state.dataBanner.data = { data: [], id: '', isDeleted: true, order: 0 };
	},
};
