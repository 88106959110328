import { RoutConfig } from '@/constant/routes';
import { AdvertiseItem } from './type';

export const SLIDER_ITEMS = [
	{
		image: '/banner/main.png',
	},

	{
		image: '/banner/nganhnuoc.png',
	},
	{
		image: '/banner/dien.jpg',
	},
	{
		image: '/banner/luyenkim.png',
	},
	{
		image: '/banner/son.png',
	},
	{
		image: '/banner/nongnghiep.png',
	},
	{
		image: '/banner/dodunggiadinh.png',
	},
	{
		image: '/banner/maymoc.png',
	},
	{
		image: '/banner/bhld.png',
	},
	{
		image: '/banner/xaydung.png',
	},
	{
		image: '/banner/wc.png',
	},
	{
		image: '/banner/vatnuoi.png',
	},
];

export const SEPCIAL_CATEGORY = [
	206, 162, 2, 177, 287, 395, 846, 915, 823, 977, 739, 1024, 1013, 929, 712, 856,
];

export const IMAGES_EXTRACATEGORY = [
	{
		id: 712,
		name: 'Ngành bảo hộ lao động',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469959/ecom_category/nganh_bhld_cicpp2.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 1,
		name: 'Ngành Điện',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469505/ecom_category/nganh_dien_u7yfgd.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.395Z',
		updatedAt: '2024-06-13T15:34:17.395Z',
	},
	{
		id: 855,
		name: 'Ngành Đồ Dùng Gia Đình',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719470044/ecom_category/nganh_gia_dinh_aqbesb.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 316,
		name: 'Ngành Kim Khí',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469962/ecom_category/nganh_kim_khi_x2vpk4.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.395Z',
		updatedAt: '2024-06-13T15:34:17.395Z',
	},
	{
		id: 640,
		name: 'Ngành Máy Móc',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469961/ecom_category/nganh_may_moc_p727pt.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 976,
		name: 'Ngành Mộc',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719470045/ecom_category/nganh_moc_xg0asq.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 1021,
		name: 'Ngành Nông Nghiệp',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469961/ecom_category/nganh_nong_nghiep_aswr7j.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.444Z',
		updatedAt: '2024-06-13T15:34:17.444Z',
	},
	{
		id: 738,
		name: 'Ngành Nước',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469958/ecom_category/nganh_nuoc_ncexww.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 286,
		name: 'Ngành Sơn',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469960/ecom_category/nganh_son_jssut7.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.395Z',
		updatedAt: '2024-06-13T15:34:17.395Z',
	},
	{
		id: 929,
		name: 'Ngành TBVS - Phòng Tắm',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469959/ecom_category/nganh_tbvs_bi77jx.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 822,
		name: 'Ngành Thiết Bị Gia Dụng',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469959/ecom_category/nganh_gia_dung_p5hrza.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
	{
		id: 1013,
		name: 'Ngành Vật Nuôi',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469964/ecom_category/nganh_vat_nuoi_idvady.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.444Z',
		updatedAt: '2024-06-13T15:34:17.444Z',
	},
	{
		id: 682,
		name: 'Ngành Xây Dựng',
		description: '',
		level: null,
		image:
			'https://res.cloudinary.com/dfyg1ymat/image/upload/v1719469958/ecom_category/nganh_xay_dung_le1t6x.png',
		isPublic: true,
		parentCategoryId: null,
		group: null,
		createdAt: '2024-06-13T15:34:17.424Z',
		updatedAt: '2024-06-13T15:34:17.424Z',
	},
];

export const SALE_PROGRAM = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720175952/sale_program/sonxit_bazcmo.png',
		title: (
			<span>
				Sơn xịt <br /> giá ưu đãi
			</span>
		),
		query: 'category=296&topCategory=286&subCategory=287&childCategory=288',
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720175952/sale_program/can_qkxss5.png',
		title: (
			<span>
				Cân Nhơn Hòa <br /> giá từ 189k
			</span>
		),
		query: 'category=915&topCategory=855&subCategory=915',
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720175971/sale_program/daydien_j6vssq.png',
		title: (
			<span>
				Dây điện sale mạnh <br /> giảm đến 500k
			</span>
		),
		query: 'category=162&topCategory=1&subCategory=162',
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720175971/sale_program/kimkhi_twca1a.png',
		title: (
			<span>
				Đồ nghề cầm tay <br /> giảm 70%++
			</span>
		),
		query: 'category=395&topCategory=316&subCategory=395&childCategory=288',
	},
];

export const SENKO_PRODUCTS: AdvertiseItem[] = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720260305/sale_program/senko/1638_odiygd.png',
		url: `${RoutConfig.VN.detail}/${4687}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720260305/sale_program/senko/1622_jfd1fl.png',
		url: `${RoutConfig.VN.detail}/${4489}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720260304/sale_program/senko/1626_mm2zeu.png',
		url: `${RoutConfig.VN.detail}/${4591}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720260304/sale_program/senko/1636_qqyna0.png',
		url: `${RoutConfig.VN.detail}/${4636}`,
	},
];

export const TRENDING: AdvertiseItem[] = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720266841/sale_program/xuhuong/1_excwlc.png',
		url: `${RoutConfig.VN.detail}/${4657}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720266842/sale_program/xuhuong/3_xgime4.png',
		url: `${RoutConfig.VN.detail}/${4502}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720266841/sale_program/xuhuong/2_z05c69.png',
		url: `${RoutConfig.VN.detail}/${3957}`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720266842/sale_program/xuhuong/4_vjoxxw.png',
		url: `${RoutConfig.VN.detail}/${4498}`,
	},
];

export const NEWS = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720273415/sale_program/news/2_lp1vbm.png',
		subString:
			'Cùng đánh giá ổ khóa Việt Tiệp có thật sự chất lượng như lời đồn? Chất lượng làm nên …',
		url: 'https://www.nppngaliem.com/tin_tuc/06',
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720273416/sale_program/news/3_jy5xbn.png',
		subString:
			'Ống luồn dây điện Sino, Giải pháp thi công lắp đặt điện hiệu quả, Linh hoạt và Tiết kiệm …',
		url: 'https://www.nppngaliem.com/tin_tuc/07',
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720273416/sale_program/news/4_wv2xuz.png',
		subString: 'Lý do sơn dầu Bạch Tuyết được thị trường và các anh thợ yêu chuộng đến như vậy …',
		url: 'https://www.nppngaliem.com/tin_tuc/08',
	},
];

export const BRANDS: AdvertiseItem[] = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720277691/sale_program/news/1_emzpds.png',
		url: `https://www.nppngaliem.com/tin_tuc/09`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720277690/sale_program/news/2_gxhc4n.png',
		url: `https://www.nppngaliem.com/tin_tuc/10`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720277691/sale_program/news/3_i4ghd9.png',
		url: `https://www.nppngaliem.com/tin_tuc/11`,
	},
];

export const DEALS: AdvertiseItem[] = [
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720276627/sale_program/deals/1_fnuecu.png',
		url: `${RoutConfig.VN.previewProduct}?category=316`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720276627/sale_program/deals/2_tlifkr.png',
		url: `${RoutConfig.VN.previewProduct}?category=640`,
	},
	{
		img: 'https://res.cloudinary.com/dfyg1ymat/image/upload/v1720276629/sale_program/deals/3_j1kw6k.png',
		url: `${RoutConfig.VN.previewProduct}?category=738&topCategory=738`,
	},
];
