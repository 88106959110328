import React from 'react';
import { CheckBoxItem, CheckBoxProps } from './interface';
import classNames from 'classnames';
import { Check } from '@/components/icons/common';

export interface SquareCheckboxProps<T extends CheckBoxItem> extends CheckBoxProps<T> {}

const SquareCheckbox = <T extends CheckBoxItem>({
	className,
	checked,
	label,
	onChange,
	value,
}: SquareCheckboxProps<T>) => {
	return (
		<label
			className={classNames([
				'relative cursor-pointer flex space-x-3 items-center font-normal',
				className,
			])}
		>
			<input
				type='radio'
				className={classNames('absolute top-0 left-0 hidden opacity-0')}
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			<span
				className={classNames(
					'w-4 h-4 border-[1px] border-solid border-primary rounded relative overflow-hidden flex items-center justify-center',
					checked && 'border-[2px] bg-white',
				)}
			>
				{checked && <Check className='w-[8px] font-bold' />}
			</span>
			{typeof label == 'string' ? <span>{label}</span> : label}
		</label>
	);
};

export default SquareCheckbox;
