import { IQueryParamsCommon } from '@/models';
import { request } from '@/utils';

export const fetchListProductNew = (params: Partial<IQueryParamsCommon>): Promise<any> => {
	return request({
		url: '/products?category=17,27,79,180,204',
		method: 'get',
		params,
	});
};

export const fetchListProductDiscount = (params: Partial<IQueryParamsCommon>): Promise<any> => {
	return request({
		url: '/Product/Discount',
		method: 'get',
		params,
	});
};

export const fetchListProductPopularity = (params: Partial<IQueryParamsCommon>): Promise<any> => {
	return request({
		url: '/products?category=17,27,79,180,204',
		method: 'get',
		params,
	});
};
