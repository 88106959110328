import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { DeviceType } from '@/constant';
import {
	Breadcrumb,
	IBannerItem,
	IBrandItem,
	ICategoryItem,
	ICategoryTreeItem,
	IProductItem,
	IVariantWithId,
	ListResponse,
} from '@/models';
import {
	handleFetchListBanner,
	handleFetchListBrand,
	handleFetchListCategory,
	handleFetchListProduct,
} from '@/store/actions';
import { HYDRATE } from 'next-redux-wrapper';

export interface IState {
	dataBrand: {
		isRequest: boolean;
		data: ListResponse<IBrandItem> | null;
	};
	dataCart: {
		isRequest: boolean;
	};
	dataProduct: {
		isRequest: boolean;
		data: IProductItem[];
	};
	dataVariantWithId: {
		isRequest: boolean;
		data: IVariantWithId['data'] | null;
	};
	productBreadcrumb?: Breadcrumb | null;
	dataCategory: {
		isRequest: boolean;
		data: any | null;
		// isRequest: boolean;
		// data: ListResponse<ICategoryItem> | null;
	};
	device: DeviceType;
	dataBanner: {
		isRequest: boolean;
		data: IBannerItem;
	};
	dataCategoryTree: {
		isRequest: boolean;
		data: any | null;
	};
	// dataCategoryTree: {
	// 	isRequest: boolean;
	// 	data: ICategoryTreeItem | null;
	// };
	isNonScrollBody: boolean;
	isOpenMenuDrawer: boolean;
}

const initialState: IState = {
	dataBrand: {
		isRequest: false,
		data: null,
	},
	dataCategoryTree: {
		isRequest: false,
		data: null,
	},
	dataVariantWithId: {
		isRequest: false,
		data: null,
	},
	dataCart: {
		isRequest: false,
	},
	isNonScrollBody: false,
	device: DeviceType.DESKTOP,
	isOpenMenuDrawer: false,
	dataCategory: {
		isRequest: false,
		data: null,
	},
	productBreadcrumb: {
		name: '',
		urlSlug: '/',
	},
	dataProduct: {
		isRequest: false,
		data: [],
	},
	dataBanner: {
		isRequest: false,
		data: { data: [], id: '', isDeleted: true, order: 0 },
	},
};

const commonSlice = createSlice({
	name: 'common',
	initialState,
	reducers: {
		...handleFetchListBrand,
		...handleFetchListCategory,
		...handleFetchListProduct,
		...handleFetchListBanner,
		setDeviceType(state, action: PayloadAction<DeviceType>) {
			state.device = action.payload;
		},
		setOpenMenuDrawer(state, action: PayloadAction<boolean>) {
			state.isOpenMenuDrawer = action.payload;
		},
		setNonScrollBody(state, action: PayloadAction<boolean>) {
			state.isNonScrollBody = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action: any) => {
			return {
				...state,
				...action.payload.common,
			};
		});
	},
});

// Action
export const commonActions = commonSlice.actions;

//selector
export const commonSelectors = (state: RootState) => state.common;

//breadcrum
export const commonProductBreadcrumSelectors = (state: RootState) => state.common.productBreadcrumb;

//deviceType
export const deviceTypeSelector = (state: RootState) => state.common.device;

// Reducer
const commonReducer = commonSlice.reducer;

export default commonReducer;
