import Slider from 'rc-slider';
import React, { useEffect, useState } from 'react';

interface IMultiRangeSlider {
	min: number;
	max: number;
	detroyTooltip?: boolean;
	onChange?: ({ min, max }: { min: number; max: number }) => void;
	render?: ({ min, max }: { min: number; max: number }) => React.ReactNode;
	defaultValues?: { min: number; max: number };
}

type IValueSlider = Pick<IMultiRangeSlider, 'max' | 'min'>;

const RangeSlider: React.FC<IMultiRangeSlider> = ({
	max,
	min,
	render,
	defaultValues,
	detroyTooltip,
	onChange,
}) => {
	const [valueSlider, setValueSlider] = useState<IValueSlider>({
		max: defaultValues?.max ?? max,
		min: defaultValues?.min ?? min,
	});

	useEffect(() => {
		if (defaultValues?.min && defaultValues?.max) {
			setValueSlider(defaultValues);
		}
	}, [defaultValues]);
	return (
		<>
			<Slider
				range
				allowCross={false}
				max={max}
				min={min}
				defaultValue={[valueSlider?.min, valueSlider?.max]}
				step={max / 10}
				handleStyle={{
					backgroundColor: '#fff',
					border: 'unset',
					opacity: 1,
					boxShadow:
						'0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
				}}
				trackStyle={{ backgroundColor: '#5E93DE' }}
				dots
				handleRender={(renderProps) => {
					return (
						<div {...renderProps.props}>
							{!detroyTooltip ? (
								<div
									className='tooltip tooltip-open before:bg-base-100 before:text-base-content after:border-[#fff_transparent_transparent_transparent]  mb-5 ml-[7px] before:shadow-range-slider'
									data-tip={Number(renderProps.props?.['aria-valuenow'])?.toLocaleString('it-IT')}
								></div>
							) : null}
						</div>
					);
				}}
				dotStyle={{
					backgroundColor: '#fff',
					border: 'unset',
					borderRadius: 'unset',
					width: '2px',
					height: '4px',
					top: 0,
				}}
				onChange={(props) => {
					onChange?.({ min: props[0], max: props[1] });
					setValueSlider((prev) => ({ ...prev, min: props[0], max: props[1] }));
				}}
			/>
			<div className='flex flex-col gap-3'>
				<div className='flex items-center gap-3 pt-[34px]'>
					<div className='flex-auto text-center bg-base-100 border border-[#D0D5DD] rounded-[5px] py-[10px]  text-primary'>
						{valueSlider?.min?.toLocaleString('it-IT')}
					</div>
					<div className='flex-auto text-center bg-base-100 border border-[#D0D5DD] rounded-[5px] py-[10px]  text-primary'>
						{valueSlider?.max?.toLocaleString('it-IT')}
					</div>
				</div>
				{render?.(valueSlider)}
			</div>
		</>
	);
};

export default RangeSlider;
