import { ButtonUI, ImageCustom } from '@/components';
import Link from 'next/link';

const EmptyOrder = () => {
	return (
		<div className='flex px-2.5 lg:px-0 pt-4 lg:pt-0 flex-col items-center h-fit lg:pb-8 text-center justify-center place-items-center'>
			<div className='relative w-[200px] md:w-[250px] lg:w-[300px] aspect-square flex justify-center'>
				<ImageCustom
					fill
					unoptimized
					className='!h-full'
					src={'/static/images/icon_empty_cart.png'}
				/>
			</div>
			<p className='text-large lg:text-large-2x pt-2 lg:pt-0 font-bold uppercase text-primary'>
				Bạn chưa có bất kì đơn hàng nào
			</p>
			<p className='text-small lg:text-base'>có vẻ như bạn vẫn chưa đưa ra lựa chọn của mình ...</p>

			<Link href={'/'} className='flex justify-center text-center mx-auto pt-4 pb-4 lg:pb-0'>
				<ButtonUI text='Mua hàng ngay' color='primary' />
			</Link>
		</div>
	);
};

export default EmptyOrder;
