export const RoutConfig = {
	Base: {
		previewProduct: '/preview-product',
		cart: '/cart',
	},
	VN: {
		previewProduct: '/san-pham',
		cart: '/gio-hang',
		detail: '/chi-tiet',
	},
};
