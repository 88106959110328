import { IParamChangeVariationCart, IParamsAddToCart } from '@/models';
import { cartActions } from '@/store/reducers/cartSlice';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useRef } from 'react';

interface IOptionsAddCart {
	isChangeRoute?: boolean;
	showNotify?: boolean;
	onMutate?: () => void;
}

export const useAddToCart = (dispatch: React.Dispatch<any>) => {
	const router = useRouter();

	const handleAddToCart = (params: IParamsAddToCart, options?: IOptionsAddCart) => {
		dispatch(
			cartActions.addToCartRequest({
				params,
				onSuccess: (res) => {
					dispatch(cartActions.setQuantityCart(res?.data?.cart_info?.cart_items?.length!));
					dispatch(cartActions.setTotalPrice(+res?.data?.cart_info?.payment?.total!));
					options?.isChangeRoute && router.push('/cart', '/gio-hang', { shallow: true });
					options?.onMutate && options?.onMutate?.();
				},
				options: {
					showNotify: options?.showNotify,
				},
			}),
		);
	};

	const handleChangeVariationsCart = (
		params: IParamChangeVariationCart,
		options?: IOptionsAddCart,
	) => {
		dispatch(
			cartActions.insertChangeVariantionsRequest({
				params,
				onSuccess: (res) => {
					dispatch(cartActions.setQuantityCart(res?.data?.cart_info?.cart_items?.length!));
					dispatch(cartActions.setTotalPrice(+res?.data?.cart_info?.payment?.total!));
					options?.isChangeRoute && router.push('/cart', '/gio-hang', { shallow: true });
					options?.onMutate && options?.onMutate?.();
				},
				options: {
					showNotify: options?.showNotify,
				},
			}),
		);
	};

	const deBounceAddToCart = useRef(
		debounce((func: () => void) => {
			func?.();
		}, 200),
	).current;

	return { handleAddToCart, handleChangeVariationsCart, deBounceAddToCart };
};
