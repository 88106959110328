import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Autoplay, FreeMode, Thumbs, type Swiper as SwiperRef } from 'swiper';
import ImageCustom from '../ImageCustom';

interface IThumnailSlider {
	dataSource: string[];
	isMobile?: boolean;
}

const ThumnailSlider: React.FC<IThumnailSlider> = ({ dataSource, isMobile }) => {
	const swiperRef = useRef<SwiperRef>();
	const [swiperIndex, setSwiperIndex] = useState<any>(1);

	const [thumbsSwiper, setThumbsSwiper] = useState<SwiperRef>();

	const moduleDesktop = [FreeMode, Autoplay, Thumbs];
	const moduleMobile = [FreeMode, Thumbs];

	return (
		<div className='thumnail-wrapper'>
			<Swiper
				onBeforeInit={(swiper) => {
					swiperRef.current = swiper;
				}}
				onSlideChange={(swiperCore) => {
					setSwiperIndex(swiperCore.realIndex + 1);
				}}
				spaceBetween={10}
				thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
				modules={isMobile ? moduleMobile : moduleDesktop}
				autoplay={
					isMobile
						? false
						: {
								delay: 2500,
								disableOnInteraction: false,
						  }
				}
				className={classNames([
					'w-full mb-[10px] relative',
					dataSource?.length > 1 ? 'h-[70%]' : 'h-[calc(100vh_-_25%)]',
					isMobile && '!h-[300px]',
				])}
			>
				{dataSource?.length ? (
					dataSource?.map((el, index) => (
						<SwiperSlide key={index}>
							<ImageCustom
								fill
								src={el}
								className='!h-full'
								objectFit={dataSource?.length > 1 ? 'contain' : 'cover'}
							/>
						</SwiperSlide>
					))
				) : (
					<SwiperSlide>
						<ImageCustom
							fill
							src={'/static/images/emptyImage.png'}
							className='!h-full'
							unoptimized
							objectFit={dataSource?.length > 1 ? 'contain' : 'cover'}
						/>
					</SwiperSlide>
				)}
				{isMobile ? (
					<div className='rounded-full z-10 border border-black/25 absolute bottom-5 right-5 py-1.5 px-3 text-[12px]'>
						{swiperIndex} / {dataSource?.length}
					</div>
				) : null}
			</Swiper>

			{dataSource?.length > 1 ? (
				<div className='relative'>
					{dataSource?.length > 4 ? (
						<button
							className={classNames([
								'swiper-btn-prev small',
								swiperIndex === 1 && 'pointer-events-none opacity-40',
							])}
							onClick={() => swiperRef.current?.slidePrev()}
						>
							<ChevronLeftIcon className='text-primary' width={24} />
						</button>
					) : null}
					<Swiper
						onSwiper={setThumbsSwiper}
						loop={true}
						spaceBetween={10}
						slidesPerView={4}
						freeMode={true}
						centeredSlides={false}
						watchSlidesProgress={true}
						modules={[FreeMode, Thumbs]}
						className='thumnail-small'
					>
						{dataSource?.map((el, index) => (
							<SwiperSlide key={index}>
								<ImageCustom fill src={el} className='!h-full' objectFit='contain' />
							</SwiperSlide>
						))}
					</Swiper>
					{dataSource?.length > 4 ? (
						<button
							className={classNames([
								'swiper-btn-next small',
								swiperIndex === dataSource?.length && 'pointer-events-none opacity-40',
							])}
							onClick={() => swiperRef.current?.slideNext()}
						>
							<ChevronRightIcon className='text-primary' width={24} />
						</button>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default React.memo(ThumnailSlider);
