import classNames from 'classnames';

import Notification from '.';
import { ButtonUI } from '../FomFileds';
import { ConfirmNotificationProps } from './types';

export const ConfirmNotification: React.FC<ConfirmNotificationProps> = ({
	message,
	contextConfirm,
	contextNotConfirm,
	onConfirm,
	onNotConfirm,
	titleHead,
}) => {
	return (
		<div
			id='confirmNotification-vuivui'
			className={classNames([
				'bg-[rgba(256,256,256,.2)] fixed top-0 left-0 right-0 bottom-0 h-full overflow-hidden w-full z-[80]',
			])}
		>
			<div
				className={classNames([
					'alignCenterScreen__fixed animation-300 lg:w-auto w-[90%] z-[80] shadow-xl',
					'opacity-100 visible',
				])}
			>
				<div
					className={classNames([
						'bg-[#FFFFFF] w-fit mx-auto px-12 py-6 rounded-lg text-[#272728]',
					])}
				>
					<div className='mb-8 w-fit'>
						<span className='mb-2 block text-center font-sfpro_semiBold text-18 font-semibold leading-7'>
							{titleHead}
						</span>
						<span className='block w-[80%] text-center mx-auto'>{message}</span>
					</div>
					<div
						className={classNames([
							'gap-4',
							contextNotConfirm
								? 'flex items-center justify-center '
								: 'flex justify-center mx-auto w-fit',
						])}
					>
						{contextNotConfirm ? (
							<ButtonUI
								text={contextNotConfirm}
								color={'outline'}
								className={'w-full'}
								onClick={() => {
									onNotConfirm();
									Notification.Confirm.remove();
								}}
							/>
						) : null}

						<ButtonUI
							text={contextConfirm}
							color={'primary'}
							className={contextNotConfirm ? 'w-full' : 'w-[200px]'}
							onClick={onConfirm}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
