import { Notification } from '@/components';
import { MESSSAGE_GLOBAL } from '@/constant';
import {
	IParamsGetOrdersWithId,
	IPreviewOrderItem,
	ParamsRequestSaga,
	SingleResponse,
} from '@/models';
import { getOrderWithId } from '@/services';
import { orderActions } from '@/store/reducers/orderSlice';
import { getErrorMessageInstance } from '@/utils';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

function* handleGetOrderWithId(
	action: PayloadAction<ParamsRequestSaga<IParamsGetOrdersWithId, IPreviewOrderItem>>,
) {
	try {
		const resp: SingleResponse<IPreviewOrderItem> = yield call(
			getOrderWithId,
			action.payload.params,
		);

		if (resp?.data?.cart_info) {
			action.payload?.onSuccess?.(resp?.data);
			yield put(orderActions.getOrderWithIdSuccess(resp?.data));
		} else {
			yield put(orderActions.getOrderWithIdFailed());
			Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
		}
	} catch (error) {
		getErrorMessageInstance(error);
		yield put(orderActions.getOrderWithIdFailed());
	}
}

export default function* orderSaga() {
	yield takeEvery(orderActions.getOrderWithIdRequest.type, handleGetOrderWithId);
}
