import classNames from 'classnames';
import React from 'react';

import { ImageCustom } from '@/components';

interface ITypeServicesCard {
	isActive?: boolean;
	className?: string;
	name: string;
	icon: string;
	onClick?: () => void;
}

const TypeServicesCard: React.FC<ITypeServicesCard> = ({
	name,
	className,
	isActive = false,
	icon,
	onClick,
}) => {
	return (
		<div
			className={classNames([
				isActive && '!btn-secondary font-bold',
				'shadow-boxTypeServiceCard flex bg-base-100',
				'py-5 px-2 rounded-small cursor-pointer',
				'group hover:!btn-secondary',
				className,
			])}
			onClick={onClick}
		>
			<div className='flex gap-[16px] items-center'>
				<div className='relative min-w-[90px] h-[90px] rounded-full  bg-base-300 overflow-hidden'>
					<ImageCustom fill src={icon} className={'aspect-square'} objectFit='contain' />
				</div>

				<div
					className={classNames([
						'text-[#1F549D]',
						'justify-start',
						isActive && '!text-base-100',
						'group-hover:!text-base-100',
					])}
				>
					{name}
				</div>
			</div>
		</div>
	);
};

export default TypeServicesCard;
