export const TextInputConfig = {
  color: {
    primary: 'input-primary',
    info: 'input-info',
    success: 'input-success',
    warning: 'input-warning',
    error: 'input-error',
    secondary: 'input-secondary',
    accent: 'input-accent',
    ghost: 'input-ghost',
    outline: 'input-outline',
    default: '',
  },
  size: {
    lg: 'input-lg',
    sm: 'input-sm',
    xs: 'input-xs',
  },
};

export const TextAreaConfig = {
  color: {
    primary: 'textarea-primary',
    info: 'textarea-info',
    success: 'textarea-success',
    warning: 'textarea-warning',
    error: 'textarea-error',
    secondary: 'textarea-secondary',
    accent: 'textarea-accent',
  },
  size: {
    lg: 'textarea-lg',
    sm: 'textarea-sm',
    xs: 'textarea-xs',
  },
};

export const SelectConfig = {
  color: {
    primary: 'select-primary',
    info: 'select-info',
    success: 'select-success',
    warning: 'select-warning',
    error: 'select-error',
    secondary: 'select-secondary',
    accent: 'select-accent',
  },
  size: {
    lg: 'select-lg',
    sm: 'select-sm',
    xs: 'select-xs',
  },
};

export const CheckboxConfig = {
  checkbox: {
    color: {
      primary: 'checkbox-primary',
      info: 'checkbox-info',
      success: 'checkbox-success',
      warning: 'checkbox-warning',
      error: 'checkbox-error',
      secondary: 'checkbox-secondary',
      accent: 'checkbox-accent',
    },
    size: {
      lg: 'checkbox-lg',
      sm: 'checkbox-sm',
      xs: 'checkbox-xs',
    },
  },

  toggle: {
    color: {
      primary: 'toggle-primary',
      info: 'toggle-info',
      success: 'toggle-success',
      warning: 'toggle-warning',
      error: 'toggle-error',
      secondary: 'toggle-secondary',
      accent: 'toggle-accent',
    },
    size: {
      lg: 'toggle-lg',
      sm: 'toggle-sm',
      xs: 'toggle-xs',
    },
  },
};

export const RadioConfig = {
  color: {
    primary: 'radio-primary',
    info: 'radio-info',
    success: 'radio-success',
    warning: 'radio-warning',
    error: 'radio-error',
    secondary: 'radio-secondary',
    accent: 'radio-accent',
  },
  size: {
    lg: 'input-lg',
    sm: 'input-sm',
    xs: 'input-xs',
  },
};

export const FileInputConfig = {
  color: {
    primary: 'file-input-primary',
    info: 'file-input-info',
    success: 'file-input-success',
    warning: 'file-input-warning',
    error: 'file-input-error',
    secondary: 'file-input-secondary',
    accent: 'file-input-accent',
  },
  size: {
    lg: 'file-input-lg',
    sm: 'file-input-sm',
    xs: 'file-input-xs',
  },
};

export const ButtonConfig = {
  color: {
    primary: 'btn-primary',
    info: 'btn-info',
    success: 'btn-success',
    warning: 'btn-warning',
    error: 'btn-error',
    secondary: 'btn-secondary',
    accent: 'btn-accent',
    ghost: 'btn-ghost',
    outline: 'btn-outline',
    default: '',
  },
  shape: {
    square: 'btn-square',
    circle: 'btn-circle',
  },
  size: {
    lg: 'btn-lg',
    sm: 'btn-sm',
    xs: 'btn-xs',
  },
};
