import { ImageCustom } from '@/components';
import { DeviceType } from '@/constant';
import { useAppSelector } from '@/hooks';
import { commonSelectors } from '@/store/reducers/commonSlice';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

const BackToTop = () => {
	const { device } = useAppSelector(commonSelectors);
	const backTopEl: any = useRef();
	const backTopElIcon: any = useRef();

	const isMobile = device === DeviceType.MOBILE;

	useEffect(() => {
		backTopEl.current = global?.window?.document?.getElementById('back-to-top');
		backTopElIcon.current = global?.window?.document?.getElementById('back-to-top-icon');
	}, []);

	global?.window?.addEventListener('scroll', () => {
		if (global?.window?.scrollY > 400) {
			backTopEl?.current?.classList?.add('activeBackToTop');
			backTopEl?.current?.classList?.remove('hiddenBackToTop');

			if (isMobile) {
				backTopElIcon?.current?.classList?.add('activeBackToTop');
				backTopElIcon?.current?.classList?.remove('hiddenBackToTop');
			}
		} else {
			backTopEl?.current?.classList?.remove('activeBackToTop');
			backTopEl?.current?.classList?.add('hiddenBackToTop');

			if (isMobile) {
				backTopElIcon?.current?.classList?.remove('activeBackToTop');
				backTopElIcon?.current?.classList?.add('hiddenBackToTop');
			}
		}
	});

	const onClickBackToTop = () => {
		global?.window?.scrollTo({ top: 0, behavior: 'smooth' });
	};

	return (
		<>
			<div
				className={classNames([
					'fixed bottom-28 right-[20px] lg:right-[22px] w-auto z-[50]',
					isMobile ? 'hidden' : 'block',
				])}
				id='back-to-top-icon'
			>
				<div className='flex flex-col absolute w-[40px] lg:w-12 right-0 bottom-4 z-50 gap-2'>
					<a
						href={'https://zalo.me/0977991865'}
						target={'_blank'}
						className='object-cover h-[40px] w-[40px] md:h-[50px] md:w-[50px] aspect-square bg-base-100 rounded-full overflow-hidden p-1 drop-shadow-lg'
						rel='noreferrer'
					>
						<ImageCustom
							src={'/static/images/icon_zalo.svg'}
							fill
							unoptimized
							className='!h-full'
						/>
					</a>
					<a
						href='tel:0977998197'
						className='hidden md:block object-cover h-[40px] w-[40px] md:h-[50px] md:w-[50px] aspect-square bg-base-100 rounded-full overflow-hidden p-2 drop-shadow-lg'
					>
						<ImageCustom
							src={'/static/images/icon_phone_new.png'}
							fill
							unoptimized
							className='!h-full'
						/>
					</a>
					<a
						target={'_blank'}
						href='https://www.facebook.com/nhaphanphoingaliem/'
						className='hidden md:block object-cover h-[40px] w-[40px] md:h-[50px] md:w-[50px] aspect-square bg-base-100 rounded-full overflow-hidden p-1 drop-shadow-lg'
						rel='noreferrer'
					>
						<ImageCustom
							src={'/static/images/icon_facebook.svg'}
							fill
							unoptimized
							className='!h-full'
						/>
					</a>
				</div>
			</div>
			<button
				id='back-to-top'
				className='fixed bottom-[74px] hidden md:bottom-16 right-5 z-[50] justify-around rounded-full pt-5px opacity-90 hover:opacity-100'
				onClick={() => onClickBackToTop()}
			>
				<ArrowUpIcon className='bg-base-100 p-3 rounded-full object-cover h-[40px] w-[40px] md:h-[50px] md:w-[50px]' />
			</button>
		</>
	);
};

export default BackToTop;
