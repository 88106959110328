import classNames from 'classnames';
import { useRoutes } from 'hooks';
import { Breadcrumb, Route } from 'models';
import Link from 'next/link';
import React from 'react';

interface BreadcrumbProps {
	className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ className = '' }) => {
	const { breadcrumbs } = useRoutes();

	return (
		<>
			{breadcrumbs && (
				<div
					className={`pl-2.5 lg:pl-0 flex my-4 font-sfpro not-italic font-normal text-[12px] lg:text-small ${
						className || ''
					}`}
				>
					{breadcrumbs.map((route: Route) => {
						return (
							<React.Fragment key={route.key}>
								{Array.isArray(route.breadcrumbs) ? (
									<>
										{route.breadcrumbs.map((breadcrumb: Breadcrumb, index: number) => {
											return (
												<Link
													key={index}
													href={`/${breadcrumb.urlSlug}`}
													className={`text-ellipsis text-[#126BFB] line-clamp-1 after:mx-[6px] after:text-[12px] after:leading-[1.2] after:text-[#828282] after:content-['/'] last:pointer-events-none last:text-black after:last:content-none hover:cursor-pointer hover:underline`}
												>
													{breadcrumb.name}
												</Link>
											);
										})}
									</>
								) : (
									<>
										{route?.breadcrumbs?.categoryId ? (
											<Link
												key={route.breadcrumbs.categoryId}
												href={`/san-pham?CategoryId=${route.breadcrumbs.categoryId}`}
												className={`text-ellipsis text-[#126BFB] whitespace-nowrap after:mx-[6px] after:text-[12px] after:leading-[1.2] after:text-[#828282] after:content-['/'] last:pointer-events-none last:text-black after:last:content-none hover:cursor-pointer hover:underline`}
											>
												{'Sản phẩm'}
											</Link>
										) : null}
										<Link
											key={route.key}
											href={`${route.breadcrumbs.urlSlug}`}
											className={classNames([
												`text-ellipsis text-[#126BFB] after:mx-[6px] after:text-[12px] after:leading-[1.2] after:text-[#828282] after:content-['/'] last:pointer-events-none last:text-black after:last:content-none hover:cursor-pointer hover:underline`,
												route.breadcrumbs.urlSlug === '/'
													? 'line-clamp-none whitespace-nowrap'
													: 'line-clamp-1',
											])}
										>
											{route.breadcrumbs.name}
										</Link>
									</>
								)}
							</React.Fragment>
						);
					})}
				</div>
			)}
		</>
	);
};

export default Breadcrumb;
