import { IParamsVariant, IQueryParamsCommon } from '@/models';
import { request } from '@/utils';

export const fetchListBrand = (params?: Partial<IQueryParamsCommon>): Promise<any> => {
	return request({
		url: '/Brand',
		method: 'get',
		params,
	});
};
export const fetchListCategory = (params?: IQueryParamsCommon): Promise<any> => {
	return request({
		url: '/categories',
		method: 'get',
		params,
	});
};

export const fetchListCategoryTree = (): Promise<any> => {
	return request({
		url: '/categories/parents',
		// url: '/Category/tree',
		method: 'get',
	});
};

export const fetchListProduct = (params: Partial<IQueryParamsCommon>): Promise<any> => {
	return request({
		url: '/Product',
		method: 'get',
		params,
	});
};

export const fetchListBanner = (): Promise<any> => {
	return request({
		url: '/Banner',
		method: 'get',
	});
};

export const fetchListVariantWithId = (params: IParamsVariant): Promise<any> => {
	return request({
		url: '/Product/Variations',
		method: 'get',
		params: {
			productId: params?.productId?.join(),
		},
	});
};
