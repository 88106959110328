import { ArrowSmallLeftIcon, ArrowUpCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import classnames from 'classnames';

import { ButtonConfig } from './configs';
import { IButtonUIProps, suffixIcon } from './types';

const LoadingIcon = () => (
	<svg
		className='text-white -ml-1 mr-3 h-5 w-5 animate-spin'
		xmlns='http://www.w3.org/2000/svg'
		fill='none'
		viewBox='0 0 24 24'
	>
		<circle
			className='opacity-25'
			cx='12'
			cy='12'
			r='10'
			stroke='currentColor'
			strokeWidth='4'
		></circle>
		<path
			className='opacity-75'
			fill='currentColor'
			d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
		></path>
	</svg>
);

export function ButtonUI(props: IButtonUIProps): JSX.Element {
	const {
		color = 'default',
		htmlType = 'button',
		className = '',
		animation = true,
		block = false,
		disabled = false,
		loading = false,
		outline = false,
		shape = '',
		size = '',
		suffixIcon,
		text = '',
		wide = false,
		btnactive = false,
		onClick,
		radius = false,
		style,
		isSticky,
		direction,
	} = props;

	const strClassName = classnames(
		'btn',
		ButtonConfig.color[color],
		outline && 'btn-outline',
		btnactive && 'btn-active',
		!animation && 'no-animation',
		block && 'btn-block',
		wide && 'btn-wide',
		radius && 'rounded-full',
		loading && 'pointer-events-none',
		shape.length > 0 && ButtonConfig.shape[shape],
		size.length > 0 && ButtonConfig.size[size],
		className,
		'my-1 gap-2',
	);

	const renderWithConditionSuffixIcon = (condition: suffixIcon) => {
		switch (condition) {
			case 'add':
				return <PlusIcon width={24} />;
			case 'search':
				return <img alt='' src='/public/assets/images/search.svg' />;
			case 'update':
				return <ArrowUpCircleIcon width={24} />;
			case 'goBack':
				return <ArrowSmallLeftIcon width={24} />;

			default:
				return null;
		}
	};

	return (
		<div
			className={classnames([
				loading ? 'cursor-not-allowed' : 'cursor-pointer',
				isSticky && 'sticky',
				direction === 'bottom' ? 'bottom-0' : 'top-0',
				'w-full',
			])}
		>
			<button
				type={htmlType}
				disabled={disabled}
				onClick={onClick}
				className={strClassName}
				style={style}
			>
				{loading ? (
					<LoadingIcon />
				) : (
					<>{suffixIcon ? renderWithConditionSuffixIcon(suffixIcon) : null}</>
				)}

				{text}
			</button>
		</div>
	);
}
