import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { handleCartActions } from '@/store/actions';
import { HYDRATE } from 'next-redux-wrapper';

export interface IStateCart {
	dataCart: {
		isRequestAdd: boolean;
		isChangeVariantionsCart: boolean;
		isRequestCreateOrder: boolean;
		isRequestGet: boolean;
		currentQuantity: number;
		totalPrice: number;
	};
}

const initialState: IStateCart = {
	dataCart: {
		isRequestAdd: false,
		isRequestGet: false,
		isChangeVariantionsCart: false,
		isRequestCreateOrder: false,
		currentQuantity: 0,
		totalPrice: 0,
	},
};

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		...handleCartActions,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action: any) => {
			return {
				...state,
				...action.payload.cart,
			};
		});
	},
});

// Action
export const cartActions = cartSlice.actions;

//selector
export const cartSelectors = (state: RootState) => state.cart;

// Reducer
const cartReducer = cartSlice.reducer;

export default cartReducer;
