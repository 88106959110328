import { PayloadAction } from '@reduxjs/toolkit';

import {
	ICartInfo,
	IOrderInfo,
	IParamChangeVariationCart,
	IParamCreateOrder,
	IParamsAddToCart,
	ParamsRequestSaga,
} from '@/models';
import { IStateCart } from '@/store/reducers/cartSlice';

export const handleCartActions = {
	addToCartRequest(
		state: IStateCart,
		_: PayloadAction<ParamsRequestSaga<IParamsAddToCart, ICartInfo>>,
	) {
		state.dataCart.isRequestAdd = true;
	},
	addToCartSuccess(state: IStateCart) {
		state.dataCart.isRequestAdd = false;
	},
	addToCartFailed(state: IStateCart) {
		state.dataCart.isRequestAdd = false;
	},
	insertChangeVariantionsRequest(
		state: IStateCart,
		_: PayloadAction<ParamsRequestSaga<IParamChangeVariationCart, ICartInfo>>,
	) {
		state.dataCart.isChangeVariantionsCart = true;
	},
	insertChangeVariantionsSuccess(state: IStateCart) {
		state.dataCart.isChangeVariantionsCart = false;
	},
	insertChangeVariantionsFailed(state: IStateCart) {
		state.dataCart.isChangeVariantionsCart = false;
	},
	createOrderRequest(
		state: IStateCart,
		_: PayloadAction<ParamsRequestSaga<IParamCreateOrder, IOrderInfo>>,
	) {
		state.dataCart.isRequestCreateOrder = true;
	},
	createOrderSuccess(state: IStateCart) {
		state.dataCart.isRequestCreateOrder = false;
	},
	createOrderFailed(state: IStateCart) {
		state.dataCart.isRequestCreateOrder = false;
	},
	setQuantityCart(state: IStateCart, action: PayloadAction<number>) {
		state.dataCart.currentQuantity = action.payload;
	},
	resetCart(state: IStateCart) {
		state.dataCart.currentQuantity = 0;
		state.dataCart.totalPrice = 0;
	},
	setTotalPrice(state: IStateCart, action: PayloadAction<number>) {
		state.dataCart.totalPrice = action.payload;
	},
};
