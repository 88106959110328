import { DeviceType } from '@/constant';
import { useAppSelector } from '@/hooks';
import { commonSelectors, deviceTypeSelector } from '@/store/reducers/commonSlice';
import classNames from 'classnames';
import { MdEmail } from 'react-icons/md';
const Footer = () => {
	const deviceType = useAppSelector(deviceTypeSelector);
	const isMobile = deviceType === DeviceType.MOBILE;

	const { dataCategoryTree } = useAppSelector(commonSelectors);

	const stateCategory = dataCategoryTree?.data?.data?.rows ?? [];

	return (
		<div className={classNames([isMobile && 'collapse-area'])}>
			<div className='bg-footer-gradient'>
				<footer className='2xl:container footer gap-6 lg:gap-24 p-4 lg:p-10 text-base-100'>
					<div className={classNames(['w-full', isMobile && 'collapse collapse-plus'])}>
						<span
							className={classNames([
								'block w-full border-b border-b-base-100 pb-2 lg:pb-6 text-large lg:text-large-2x font-bold',
								isMobile && 'collapse-title pl-0',
							])}
						>
							GIỚI THIỆU
						</span>
						<input type='checkbox' className='peer lg:hidden' />
						<div
							className={classNames([
								'grid grid-cols-2 w-full gap-6 pt-2 font-bold',
								isMobile && 'collapse-content pl-0',
							])}
						>
							<a className='text-[12px] lg:text-base link-hover link' href='/gioi-thieu'>
								VỀ NGA LIÊM
							</a>
							<a className='text-[12px] lg:text-base link-hover link'>DỊCH VỤ BẢO HÀNH</a>
							<iframe
								src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7825.174617739437!2d107.142173!3d11.291717!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31748f7ae13a8eb1%3A0xc6a9d2aee4ad0b68!2zTkjDgCBQSMOCTiBQSOG7kEkgTkdBIExJw4pN!5e0!3m2!1svi!2sus!4v1678344238484!5m2!1svi!2sus'
								title='map-ngaliem'
								className='col-span-2 w-full aspect-square'
							></iframe>
						</div>
					</div>
					<div className={classNames(['w-full', isMobile && 'collapse collapse-plus'])}>
						<span
							className={classNames([
								'block w-full border-b border-b-base-100 pb-2 lg:pb-6 text-large lg:text-large-2x font-bold',
								isMobile && 'collapse-title pl-0',
							])}
						>
							SẢN PHẨM
						</span>
						<input type='checkbox' className='peer lg:hidden' />
						<div
							className={classNames([
								'grid grid-cols-2 w-full gap-6 pt-2 font-bold',
								isMobile && 'collapse-content pl-0',
							])}
						>
							{stateCategory?.length
								? stateCategory?.map((l: any, index: any) => (
										<a
											className='text-[12px] uppercase lg:text-base link-hover link'
											href={`/san-pham?category=${l?.id}`}
											key={index}
										>
											{l?.name}
										</a>
								  ))
								: null}
						</div>
					</div>
					<div className={classNames(['w-full', isMobile && 'collapse collapse-plus'])}>
						<span
							className={classNames([
								'block w-full border-b border-b-base-100 pb-2 lg:pb-6 text-large lg:text-large-2x font-bold',
								isMobile && 'collapse-title pl-0',
							])}
						>
							LIÊN HỆ
						</span>
						<input type='checkbox' className='peer lg:hidden' />
						<div className={classNames(['pt-2 lg:pt-6', isMobile && 'collapse-content pl-0'])}>
							<div className='space-y-2'>
								<p className='font-bold text-small lg:text-medium'>
									CÔNG TY TNHH TM &#38; DV NGA LIÊM
								</p>
								<div className='flex flex-col space-y-1 font-thin'>
									<a
										className='hover:underline'
										target={'_blank'}
										href='https://www.google.com/maps/place/356+%E1%BA%A4p+4+-+C%C3%A2y+C%E1%BA%A7y,+Ph%C3%BA+L%C3%BD,+V%C4%A9nh+C%E1%BB%ADu,+%C4%90%E1%BB%93ng+Nai,+Vi%E1%BB%87t+Nam/@11.2983244,107.1388216,17z/data=!3m1!4b1!4m5!3m4!1s0x31748e7b34f8df23:0xc3e69b618ac8cef6!8m2!3d11.2983244!4d107.1410103?hl=vi-VN'
										rel='noreferrer'
									>
										356 Cây Cầy, Ấp 4, Phú Lý, Vĩnh Cửu, Đồng Nai
									</a>
									<a href='tel:0977998197' className='hover:underline'>
										ĐT: 0977998197
									</a>
									<a href='tel:0977991865' className='hover:underline'>
										ĐT: 0977991865
									</a>
								</div>
							</div>
							<div className='space-y-2 block pt-6'>
								<p className='text-small lg:text-medium font-bold'>
									CHI NHÁNH VĨNH CỬU &#38; SHOWROOM NGA LIÊM TẠI ĐỒNG NAI
								</p>
								<div className='flex flex-col space-y-1 font-thin'>
									<a
										className='hover:underline'
										target={'_blank'}
										href='https://www.google.com/maps/place/417+%E1%BA%A4p+4+-+C%C3%A2y+C%E1%BA%A7y,+Ph%C3%BA+L%C3%BD,+V%C4%A9nh+C%E1%BB%ADu,+%C4%90%E1%BB%93ng+Nai,+Vi%E1%BB%87t+Nam/@11.2983244,107.1388216,17z/data=!3m1!4b1!4m5!3m4!1s0x31748e7b34f8df23:0xfab4828394a888fb!8m2!3d11.2983244!4d107.1410103?hl=vi-VN'
										rel='noreferrer'
									>
										417, Ấp 4, Phú Lý, Vĩnh Cửu, Đồng Nai
									</a>
									<a href='tel:0977995744' className='hover:underline'>
										ĐT: 0977995744
									</a>
									<a href='tel:0977998197' className='hover:underline'>
										ĐT: 0977998197
									</a>
								</div>
							</div>
							<div className='space-y-2 block pt-6'>
								<p className='text-small lg:text-medium font-bold'>
									CHI NHÁNH BIÊN HÒA &#38; SHOWROOM NGA LIÊM TẠI ĐỒNG NAI
								</p>
								<div className='flex flex-col space-y-1 font-thin'>
									<a
										className='hover:underline'
										target={'_blank'}
										href='https://www.google.com/maps/place/%C4%90.+%C4%90%E1%BB%93ng+Kh%E1%BB%9Fi,+%C4%90%E1%BB%93ng+Nai,+Vi%E1%BB%87t+Nam/@10.9755794,106.8483564,17z/data=!3m1!4b1!4m6!3m5!1s0x3174dc4ea9a32a89:0xc59bb34042d58948!8m2!3d10.9755794!4d106.8505451!16s%2Fg%2F1tfzzkn7?hl=vi-VN'
										rel='noreferrer'
									>
										90/39 Khu phố 8, Đồng Khởi, Tân Phong, Biên Hòa, Đồng Nai
									</a>
									<a href='tel:0977991865' className='hover:underline'>
										ĐT: 0977991865
									</a>
									<a href='tel:0977991546' className='hover:underline'>
										ĐT: 0977991546
									</a>
								</div>
							</div>
						</div>
					</div>
				</footer>
				<footer className='2xl:container footer px-2.5 lg:px-10 py-4 text-base-100'>
					<div className='md:place-self-center md:justify-self-end'>
						<div className='grid grid-flow-col gap-4'>
							<a href='mailto:nppngaliem@gmail.com'>
								<div className='flex items-center gap-3 font-bold'>
									<MdEmail size={30} />
									<span className='text-small'>Email: nppngaliem@gmail.com</span>{' '}
								</div>
							</a>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default Footer;
