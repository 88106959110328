import { ACCESS_TOKEN, MESSSAGE_GLOBAL, REFRESH_TOKEN } from '@/constant';
import { fetchRefreshLoginGuest } from '@/services';
import { Notification } from 'components';
import Cookies from 'js-cookie';
import moment from 'moment';

const getErrorMessageInstance = async (error: unknown) => {
	const { response }: any = error;
	const refreshToken = Cookies.get(REFRESH_TOKEN);
	if (response?.status === 401) {
		Notification.Confirm.show(
			'',
			'Phiên đăng nhập của bạn đã hết hạn, vui lòng tải lại trang',
			'Đồng ý',
			'',
			async () => {
				try {
					const responseRefreshToken = await fetchRefreshLoginGuest({
						refreshToken: refreshToken!,
					});
					const { access_token, access_token_expries_at, refresh_token, refresh_token_expries_at } =
						responseRefreshToken?.data ?? {};
					Cookies.set(ACCESS_TOKEN, access_token, {
						httpOnly: false,
						sameSite: 'lax',
						maxAge: +moment(access_token_expries_at!).unix() - moment().unix(),
					});
					Cookies.set(REFRESH_TOKEN, refresh_token, {
						httpOnly: false,
						sameSite: 'lax',
						maxAge: +moment(refresh_token_expries_at!).unix() - moment().unix(),
					});
					Notification.Confirm.remove();
				} catch (error) {
					Cookies.remove(REFRESH_TOKEN);
					Cookies.remove(ACCESS_TOKEN);
					localStorage.clear();
					window.location.reload();
				}
			},
			() => false,
		);
	} else if (response?.data?.message) {
		return Notification.Info.default(response?.data?.message, 'ERROR', 3000);
	} else {
		return Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
	}
};

export default getErrorMessageInstance;
