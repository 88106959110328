import classNames from 'classnames';
import Image, { ImageProps } from 'next/image';
import React, { useState } from 'react';

type TypeObjectFit = 'cover' | 'contain';
interface ImgCustomProps extends Omit<ImageProps, 'alt'> {
	alt?: string;
	objectFit?: TypeObjectFit;
	classnameImage?: string;
	fallbackSrc?: string;
}

interface ISourceImageProps {
	isLoaded?: boolean;
	isError?: boolean;
}

export const BlurImage =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKYAAAC7CAYAAADvwOUyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHvSURBVHgB7dJBAQAgDAChaf+CS6M17gEZOLv7BmLuQJCYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCSJSZKYJIlJkpgkiUmSmCR9T+QFAOjVO3IAAAAASUVORK5CYII=';

const ImageCustom: React.FC<ImgCustomProps> = ({
	classnameImage,
	objectFit = 'cover',
	fallbackSrc = '/static/images/emptyImage.png',
	...rest
}) => {
	const [error, setError] = useState(false);
	const [src, setSrc] = useState<ISourceImageProps>({ isLoaded: false, isError: false });

	return (
		<div
			style={{ color: '#ffffff' }}
			className={classNames(['w-full h-fit relative', rest?.className])}
		>
			{rest?.src ? (
				<>
					{rest?.unoptimized ? (
						<img
							onError={() => setError(true)}
							className={classNames([
								objectFit === 'cover' ? 'object-cover' : 'object-contain',
								classnameImage,
							])}
							src={!error ? (rest?.src as string) : fallbackSrc}
							alt={rest?.alt ?? ''}
						/>
					) : (
						<Image
							{...rest}
							src={src?.isError ? fallbackSrc : rest?.src}
							alt={rest?.alt ?? ''}
							placeholder='blur'
							sizes='(min-width: 60em) 24vw,
							(min-width: 28em) 45vw,
							100vw'
							blurDataURL={BlurImage}
							className={classNames([
								objectFit === 'cover' ? 'object-cover' : 'object-contain',
								classnameImage,
								'duration-300 transition-all aspect-square',
								src?.isLoaded ? 'opacity-100' : 'opacity-0',
							])}
							onLoadingComplete={() => setSrc({ isLoaded: true })}
							onError={() => setSrc({ isLoaded: false, isError: true })}
						/>
					)}
				</>
			) : null}
		</div>
	);
};

export default React.memo(ImageCustom);
