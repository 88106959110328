import {
	ButtonUI,
	IconAddCartNow,
	ImageCustom,
	SkeletonImage,
	SkeletonLine,
	TagDiscount,
} from '@/components';
import { IProductItem } from '@/models';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface IProductCardSimilar {
	dataProductSimilar: IProductItem[];
	isMobile?: boolean;
	isLoadingSimilar?: boolean;
}

const sliderSettings = {
	320: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	680: {
		slidesPerView: 2,
		// spaceBetween: 20,
	},
	1024: {
		slidesPerView: 5,
		// spaceBetween: 20,
	},
};

const ProductCardSimilar = React.forwardRef(
	({ dataProductSimilar, isMobile, isLoadingSimilar }: IProductCardSimilar, ref: any) => {
		const router = useRouter();
		const swiperRef = useRef<any>();
		return (
			<div className='pb-10 md:pb-14 lg:pb-28 px-1 lg:px-0 relative'>
				{dataProductSimilar?.length ? (
					// <div
					// 	className={classNames(['grid', isMobile ? 'grid-cols-2 gap-1' : 'grid-cols-4 gap-11'])}
					// >
					// 	{dataProductSimilar?.map((m, index) => (
					// 		<div
					// 			className='rounded-[10px] relative bg-base-100 overflow-hidden shadow-productCard'
					// 			key={index}
					// 			ref={ref}
					// 		>
					// 			<IconAddCartNow data={m} />
					// 			<span
					// 				onClick={() => router.push(`/product-detail/[slug]`, `/chi-tiet/${m?.id}`)}
					// 				className='cursor-pointer'
					// 			>
					// 				<div className='aspect-square w-full'>
					// 					<ImageCustom
					// 						src={m?.data?.images?.[0]}
					// 						fill
					// 						className='!h-full'
					// 						objectFit='contain'
					// 					/>
					// 				</div>
					// 				<div className='flex flex-col space-y-3 p-4 text-left'>
					// 					<p className='text-medium lg:text-large line-clamp-2'>{m?.data?.name}</p>
					// 					<div className='text-red-custom text-lg font-bold'>
					// 						{m?.data?.currentPrice?.toLocaleString('it-IT')}₫
					// 					</div>
					// 				</div>
					// 			</span>
					// 		</div>
					// 	))}
					// </div>

					<>
						{!isLoadingSimilar ? (
							<button className='swiper-btn-prev' onClick={() => swiperRef.current?.slidePrev()}>
								<ChevronLeftIcon className='text-primary' width={24} />
							</button>
						) : null}

						<Swiper
							onBeforeInit={(swiper) => {
								swiperRef.current = swiper;
							}}
							// style={{ height: 'auto', marginTop: 28 }}
							breakpoints={sliderSettings}
							grid={{
								rows: 1,
							}}
							autoplay={{
								delay: 2500,
								disableOnInteraction: false,
							}}
							// modules={[Grid, Autoplay]}
							className='mySwiper'
							loop={true}
						>
							{dataProductSimilar?.length ? (
								<div className='custom_wrapper'>
									{dataProductSimilar.map((slideContent: any, index: any) => {
										const { variants, images, name } = slideContent ?? {};

										const priceSplit = variants
											.map((item: any) => {
												return {
													currentPrice: item.salePrice,
													split: item.revenueSplit,
												};
											})
											.sort((a: any, b: any) => a.currentPrice - b.currentPrice);

										return (
											<SwiperSlide
												className={classNames(['slide-product p-[20px]'])}
												key={index}
												virtualIndex={index}
											>
												<div className='custom_item w-full h-full rounded overflow-hidden'>
													<div className='card bg-white'>
														<IconAddCartNow data={slideContent} />

														<span
															className='group cursor-pointer'
															style={{ width: 'inherit' }}
															onClick={() =>
																router.push(
																	`/product-detail/[slug]`,
																	`/chi-tiet/${slideContent?.id}`,
																)
															}
														>
															<div className='w-full relative h-full'>
																<div className='w-full pb-5 overflow-hidden'>
																	<ImageCustom
																		fill
																		src={images[0]}
																		className={
																			'aspect-square !h-auto group-hover:scale-110 animation-200'
																		}
																		alt={name}
																		objectFit='contain'
																	/>
																</div>
																<div className='space-y-[11px] text-center p-5'>
																	<h6 className='text-[##333333] text-large line-clamp-2 min-h-[60px] font-bold'>
																		{name}
																	</h6>

																	<div
																		className={classNames([
																			priceSplit && 'flex items-center gap-1 justify-center',
																		])}
																	>
																		<div className='text-red-custom text-large font-bold'>
																			{priceSplit[0]?.currentPrice.toLocaleString('it-IT')}₫
																		</div>
																		{priceSplit[0] && priceSplit[0].split < 100 ? (
																			<TagDiscount percent={100 - priceSplit[0].split} base />
																		) : null}
																	</div>
																</div>
															</div>
														</span>
													</div>
												</div>
											</SwiperSlide>
										);
									})}
								</div>
							) : (
								<div className='grid grid-cols-5 gap-5'>
									{[...new Array(5)]?.map((k, i) => (
										<div key={i} className='slide-product bg-base-100'>
											<div className='w-full h-full'>
												<div className='w-full pb-4 aspect-square relative overflow-hidden'>
													<SkeletonImage />
												</div>
												<div className='space-y-[16px] text-left py-9 px-5'>
													<SkeletonLine />
												</div>
											</div>
										</div>
									))}
								</div>
							)}
						</Swiper>

						{!isLoadingSimilar ? (
							<button className='swiper-btn-next' onClick={() => swiperRef.current?.slideNext()}>
								<ChevronRightIcon className='text-primary' width={24} />
							</button>
						) : null}
					</>
				) : (
					<div
						className={classNames(['grid', isMobile ? 'grid-cols-2 gap-1' : 'grid-cols-4 gap-11'])}
						ref={ref}
					>
						{isLoadingSimilar ? (
							[...new Array(4)]?.map((_, i) => (
								<div
									className='rounded-[10px] bg-base-100 overflow-hidden shadow-productCard'
									key={i}
								>
									<div className='aspect-square w-full'>
										<SkeletonImage />
									</div>
									<div className='p-4'>
										<SkeletonLine />
									</div>
								</div>
							))
						) : (
							<>
								<div className='text-center italic'>Hiện tại không có sản phẩm đề xuất nào !</div>
								<ButtonUI
									text='Về trang chủ'
									className='btn-primary flex justify-center mx-auto'
									onClick={() => router.push('/')}
								/>
							</>
						)}
					</div>
				)}
			</div>
		);
	},
);

export default ProductCardSimilar;
