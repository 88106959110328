import { ButtonUI, MessageErrors, SkeletonLine } from '@/components';
import { useAppDispatch } from '@/hooks';
import { IProductItem } from '@/models';
import { hanleGetVariantWithId } from '@/modules/cart/helper/useGetVariantWithId';
import { useFormVariants } from '@/modules/product-detail/helper';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useEffect } from 'react';

interface IModalSelectVariant {
	data: IProductItem;
	isLoading: boolean;
	productIdSelect: string;
	variationName?: string;
	open: boolean;
	defaultvariationId?: string;
	onSubmit: (idVariantSelect: string) => void;
	onActive?: (value: boolean) => void;
}

const ModalSelectVariant: React.FC<IModalSelectVariant> = ({
	data,
	onSubmit,
	productIdSelect,
	variationName,
	open,
	defaultvariationId,
	onActive,
}) => {
	const {
		methods,
		dataVariantSelected,
		productVariantSelected,
		// renderValuesWithIdVariant,
		setDataVariantSelected,
	} = useFormVariants(data);
	const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	methods.reset(
	// 		data?.data?.tierVariations
	// 			?.map((_, index) => `tiervariant_${index + 1}`)
	// 			?.reduce((p, r) => (p = { ...p, [r]: '' }), {}),
	// 	);
	// }, [data, methods]);

	// useEffect(() => {
	// 	if (data?.data?.tierVariations) {
	// 		// setDataVariantSelected(prev=>{
	// 		const lengthIdProduct = productIdSelect?.length;
	// 		const arrayAttributes = [
	// 			...String(
	// 				data?.variations
	// 					?.find((el) => el?.id === defaultvariationId)
	// 					?.code?.slice(lengthIdProduct),
	// 			),
	// 		];

	// 		const valuesDefaults = arrayAttributes?.map((v, index) => {
	// 			return {
	// 				key: data?.data?.tierVariations?.[index]?.name,
	// 				value: +v,
	// 			};
	// 		});

	// 		if (valuesDefaults?.length) {
	// 			valuesDefaults?.forEach((v, index) => {
	// 				return methods.setValue(`tiervariant_${index + 1}`, v?.value, {
	// 					shouldValidate: true,
	// 				});
	// 			});

	// 			setDataVariantSelected(valuesDefaults);
	// 		}
	// 	}
	// }, [data?.data?.tierVariations]);

	// const isOutOfStock =
	// 	!data?.quantity && !renderValuesWithIdVariant(productVariantSelected)?.data?.quantity;

	return (
		<div className='w-full lg:w-fit relative'>
			<div
				onClick={() => {
					onActive?.(true);
					hanleGetVariantWithId({ dispatch, productId: [productIdSelect] });
				}}
				className='text-base hidden cursor-pointer group lg:block font-normal text-[#71717A]'
			>
				<div className='flex items-center gap-1 text-base text-[#71717A]'>
					<span className='whitespace-nowrap group-hover:text-primary'>Phân loại hàng:</span>
					<ChevronDownIcon width={20} className='group-hover:text-primary' />
				</div>
			</div>
			<div
				onClick={() => {
					onActive?.(true);
					hanleGetVariantWithId({ dispatch, productId: [productIdSelect] });
				}}
				className='flex lg:hidden cursor-pointer text-small justify-end font-normal text-[#71717A]'
			>
				<div className='flex items-center gap-1 text-small text-[#71717A]'>
					<span className='whitespace-nowrap'>Phân loại hàng:</span>{' '}
					<div className='flex items-center gap-1'>
						{variationName} <ChevronDownIcon width={14} />
					</div>
				</div>
			</div>
			{/* <form
				onSubmit={methods.handleSubmit(() => {
					onSubmit(data?.variations?.find((el) => el?.code === productVariantSelected)?.id!);
					methods.reset(
						data?.data?.tierVariations
							?.map((_, index) => `tiervariant_${index + 1}`)
							?.reduce((p, r) => (p = { ...p, [r]: '' }), {}),
					);
					onActive?.(false);
					setDataVariantSelected([]);
				})}
				className={classNames([
					'flex absolute top-[100%] z-[50] flex-col shadow-default gap-6 p-5 bg-base-100 rounded-box w-72',
					open ? 'block' : 'hidden',
				])}
			>
				{data?.data?.tierVariations?.length ? (
					data?.data?.tierVariations?.map((v, index) => (
						<div className='flex flex-col gap-2.5 space-y-1 lg:space-y-2.5' key={index}>
							<div className='text-small lg:text-base text-left whitespace-nowrap'>{v?.name}:</div>
							<div className='flex flex-wrap items-center gap-2.5'>
								{v?.attributtes?.map((attr, i) => (
									<button
										className={classNames([
											'bg-[#ECEAEA] font-normal capitalize btn border-none hover:bg-primary hover:text-base-100 rounded-[16px] px-[12px] py-[6px] text-small text-primary',
											dataVariantSelected?.find((el) => el?.key === v?.name && el?.value === i + 1)
												?.value && '!bg-primary !text-base-100',
											methods.getFieldState(`tiervariant_${index + 1}`).error &&
												'!border-red-custom !border !border-solid',
										])}
										key={i}
										type='button'
										onClick={() =>
											setDataVariantSelected((prev) => {
												const key = `${v?.name}`;
												const checkExisted = prev?.some((el) => el?.key === key);
												methods.setValue(`tiervariant_${index + 1}`, i + 1, {
													shouldValidate: true,
												});
												if (!checkExisted) {
													return [...prev, { key, value: i + 1 }];
												}
												return prev?.map((el) => (el?.key === key ? { key, value: i + 1 } : el));
											})
										}
									>
										{attr}
									</button>
								))}
							</div>
							<MessageErrors
								name={`tiervariant_${index + 1}`}
								className='mt-1'
								errors={methods.formState.errors}
								messageExtra={`Vui lòng chọn ${v.name}`}
							/>
						</div>
					))
				) : (
					<SkeletonLine />
				)}

				<div className='flex items-center gap-2'>
					<ButtonUI
						color='outline'
						text='Huỷ'
						htmlType='button'
						onClick={() => onActive?.(false)}
						className='text-center w-full'
					/>
					<ButtonUI
						color='primary'
						text='Xác nhận'
						htmlType='submit'
						className='text-center w-full'
					/>
				</div>
			</form> */}
		</div>
	);
};

export default React.memo(ModalSelectVariant);
