import React from 'react';
import { KeyCart } from '@/modules/shared/constants';
import { Notification } from '@/components';

export interface CartItem {
	productId: number;
	variantId?: number;
	quantity?: number;
}

export const useCart = () => {
	const getCart = (): Array<CartItem> => {
		try {
			if (localStorage.getItem(KeyCart) === null) {
				localStorage.setItem(KeyCart, JSON.stringify([]));
				return JSON.parse(localStorage.getItem(KeyCart)!);
			}

			return JSON.parse(localStorage.getItem(KeyCart)!);
		} catch (error) {
			localStorage.removeItem(KeyCart);
			return [];
		}
	};

	const addCart = (id: number, quantity?: number, variantId?: number) => {
		const cart = getCart();
		const idList = cart.map(({ productId }) => productId);
		const cartParams: CartItem = {
			productId: id,
			...(variantId && { variantId }),
			quantity: quantity ?? 1,
		};
		if (!idList.includes(id)) {
			cart.push(cartParams);
			localStorage.setItem(KeyCart, JSON.stringify(cart));
			Notification.Info.default('Sản phẩm đã được thêm vào Giỏ hàng', 'SUCCESS', 3000);
			window.dispatchEvent(new Event('storage'));
		} else {
			Notification.Info.default('Sản phẩm đã có trong giỏ hàng', 'ERROR', 3000);
		}
	};

	const removeCart = (id: number) => {
		const cart = getCart();
		const newListCart = cart.filter((item) => item.productId !== id);
		localStorage.setItem(KeyCart, JSON.stringify(newListCart));
	};

	const clearCart = () => {
		localStorage.setItem(KeyCart, JSON.stringify([]));
	};

	return { getCart, addCart, removeCart, clearCart };
};
