import { PayloadAction } from '@reduxjs/toolkit';

import { IBrandItem, IQueryParamsCommon, ListResponse, ParamsRequestSaga } from '@/models';
import { IState } from '@/store/reducers/commonSlice';

export const handleFetchListBrand = {
	fetchListBrandRequest(
		state: IState,
		_: PayloadAction<ParamsRequestSaga<Partial<IQueryParamsCommon>>>,
	) {
		state.dataBrand.isRequest = true;
		state.dataBrand.data = null;
	},
	fetchListBrandSuccess(state: IState, action: PayloadAction<ListResponse<IBrandItem> | null>) {
		state.dataBrand.data = action.payload;
		state.dataBrand.isRequest = false;
	},
	fetchListBrandFailed(state: IState) {
		state.dataBrand.isRequest = false;
		state.dataBrand.data = null;
	},
};
