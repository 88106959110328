import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

import { IPreviewOrderItem } from '@/models';
import { handleOrderActions } from '@/store/actions/order';
import { HYDRATE } from 'next-redux-wrapper';

export interface IStateOrder {
	dataOrder: {
		isRequestGetOrderWithId: boolean;
		dataOrderWithId: IPreviewOrderItem | null;
	};
}

const initialState: IStateOrder = {
	dataOrder: {
		isRequestGetOrderWithId: false,
		dataOrderWithId: null,
	},
};

const orderSlice = createSlice({
	name: 'Order',
	initialState,
	reducers: {
		...handleOrderActions,
	},
	extraReducers: (builder) => {
		builder.addCase(HYDRATE, (state, action: any) => {
			return {
				...state,
				...action.payload.order,
			};
		});
	},
});

// Action
export const orderActions = orderSlice.actions;

//selector
export const orderSelectors = (state: RootState) => state.order;

// Reducer
const orderReducer = orderSlice.reducer;

export default orderReducer;
