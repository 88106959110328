import { PayloadAction } from '@reduxjs/toolkit';

import { ICategoryItem, ICategoryTreeItem, ListResponse, ParamsRequestSaga } from '@/models';
import { IState } from '@/store/reducers/commonSlice';

export const handleFetchListCategory = {
	fetchListCategoryRequest(
		state: IState,
		_: PayloadAction<ParamsRequestSaga<ListResponse<ICategoryItem>>>,
	) {
		state.dataCategory.isRequest = true;
	},
	fetchListCategorySuccess(state: IState, action: PayloadAction<ListResponse<ICategoryItem>>) {
		state.dataCategory.data = action.payload;
		state.dataCategory.isRequest = false;
	},
	fetchListCategoryFailed(state: IState) {
		state.dataCategory.isRequest = false;
	},

	fetchListCategoryTreeRequest(state: IState) {
		state.dataCategoryTree.isRequest = true;
		state.dataCategoryTree.data = null;
	},
	fetchListCategoryTreeSuccess(state: IState, action: PayloadAction<ICategoryTreeItem>) {
		state.dataCategoryTree.data = action.payload;
		state.dataCategoryTree.isRequest = false;
	},
	fetchListCategoryTreeFailed(state: IState) {
		state.dataCategoryTree.isRequest = false;
		state.dataCategoryTree.data = null;
	},
};
