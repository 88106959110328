import { combineReducers } from '@reduxjs/toolkit';

import cart from './reducers/cartSlice';
import common from './reducers/commonSlice';
import order from './reducers/orderSlice';

const rootReducer = combineReducers({
	common,
	cart,
	order,
});

export default rootReducer;
