import React from 'react';

export const useScrollDirection = () => {
	const [isScrollDown, setIsScrollDown] = React.useState<boolean>(true);
	const [lastY, setLastY] = React.useState<number>(0);

	React.useEffect(() => {
		const onScroll = () => {
			updateScrollDirection();
		};

		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, [lastY]);

	const updateScrollDirection = () => {
		const scrollY = window.pageYOffset;

		if (scrollY > lastY) {
			setIsScrollDown(true);
		} else if (scrollY < lastY) {
			setIsScrollDown(false);
		}
		setLastY(scrollY);
	};

	return {
		isScrollDown,
	};
};
