import { PayloadAction } from '@reduxjs/toolkit';

import {
	Breadcrumb,
	IParamsVariant,
	IProductItem,
	IVariantWithId,
	ParamsRequestSaga,
} from '@/models';
import { IState } from '@/store/reducers/commonSlice';

export const handleFetchListProduct = {
	fetchListProductRequest(state: IState, _: PayloadAction<ParamsRequestSaga<IProductItem[]>>) {
		state.dataProduct.isRequest = true;
		state.dataProduct.data = [];
	},
	fetchListProductSuccess(state: IState, action: PayloadAction<IProductItem[]>) {
		state.dataProduct.data = action.payload;
		state.dataProduct.isRequest = false;
	},
	fetchListProductFailed(state: IState) {
		state.dataProduct.isRequest = false;
		state.dataProduct.data = [];
	},

	fetchListVariantWithIdRequest(
		state: IState,
		_: PayloadAction<ParamsRequestSaga<IParamsVariant, IVariantWithId>>,
	) {
		state.dataVariantWithId.isRequest = true;
		state.dataVariantWithId.data = null;
	},
	fetchListVariantWithIdSuccess(state: IState, action: PayloadAction<IVariantWithId['data']>) {
		state.dataVariantWithId.data = action.payload;
		state.dataVariantWithId.isRequest = false;
	},
	fetchListVariantWithIdFailed(state: IState) {
		state.dataVariantWithId.isRequest = false;
		state.dataVariantWithId.data = null;
	},

	setProductBreadcrumb: (state: IState, action: PayloadAction<Breadcrumb>) => {
		state.productBreadcrumb = action.payload;
	},
};
