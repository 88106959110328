import { Notification } from '@/components';
import { MESSSAGE_GLOBAL } from '@/constant';
import {
	ICartInfo,
	IOrderInfo,
	IParamChangeVariationCart,
	IParamCreateOrder,
	IParamsAddToCart,
	ParamsRequestSaga,
} from '@/models';
import { addToCart, changeVariationsCart, createOrder } from '@/services';
import { cartActions } from '@/store/reducers/cartSlice';
import { getErrorMessageInstance } from '@/utils';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

function* handleAddToCart(action: PayloadAction<ParamsRequestSaga<IParamsAddToCart, ICartInfo>>) {
	try {
		const resp: ICartInfo = yield call(addToCart, action.payload.params);

		if (resp?.data?.cart_info) {
			action.payload?.onSuccess?.(resp);
			yield put(cartActions.addToCartSuccess());
			action.payload.options?.showNotify &&
				Notification.Info.default('Sản phẩm đã được thêm vào Giỏ hàng', 'SUCCESS', 3000);
		} else {
			yield put(cartActions.addToCartFailed());
			Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
		}
	} catch (error) {
		getErrorMessageInstance(error);
		yield put(cartActions.addToCartFailed());
	}
}

function* handleChangeVariationsCart(
	action: PayloadAction<ParamsRequestSaga<IParamChangeVariationCart, ICartInfo>>,
) {
	try {
		const resp: ICartInfo = yield call(changeVariationsCart, action.payload.params);

		if (resp?.data?.cart_info) {
			action.payload?.onSuccess?.(resp);
			yield put(cartActions.insertChangeVariantionsSuccess());
			action.payload.options?.showNotify &&
				Notification.Info.default('Thay đổi phân loại hàng thành công', 'SUCCESS', 3000);
		} else {
			yield put(cartActions.insertChangeVariantionsFailed());
			Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
		}
	} catch (error) {
		getErrorMessageInstance(error);
		yield put(cartActions.insertChangeVariantionsFailed());
	}
}

function* handleCreateOrder(
	action: PayloadAction<ParamsRequestSaga<IParamCreateOrder, IOrderInfo>>,
) {
	Notification.Loading.default();
	try {
		const resp: IOrderInfo = yield call(createOrder, action.payload.params);

		if (resp?.data?.order_info) {
			action.payload?.onSuccess?.(resp);
			Notification.Loading.remove();
			yield put(cartActions.createOrderSuccess());
		} else {
			yield put(cartActions.createOrderFailed());
			Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
		}
	} catch (error) {
		getErrorMessageInstance(error);
		yield put(cartActions.createOrderFailed());
	}
}

export default function* cartSaga() {
	yield takeEvery(cartActions.addToCartRequest.type, handleAddToCart);
	yield takeEvery(cartActions.insertChangeVariantionsRequest.type, handleChangeVariationsCart);
	yield takeEvery(cartActions.createOrderRequest.type, handleCreateOrder);
}
