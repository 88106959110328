// eslint-disable-next-line simple-import-sort/imports
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import { Autoplay, Grid, type Swiper as SwiperRef } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';

import {
	ButtonUI,
	IconAddCartNow,
	ImageCustom,
	ProductCard,
	SkeletonImage,
	SkeletonLine,
	TagDiscount,
} from '@/components';
import { TYPE_PRODUCT } from '@/constant';
import { IProductItem, ListResponse } from '@/models';
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCart } from '@/modules/shared/hooks';

type TypeColors = 'primary' | 'secondary' | 'danger' | 'lightBlue';

const RenderColor: { [key in TypeColors]: string } = {
	primary: 'bg-primary-gradient',
	danger: 'bg-[#FF685F]',
	lightBlue: 'bg-neutral-gradient',
	secondary: 'bg-secondary',
};

const RenderTitleColor: { [key in TypeColors]: string } = {
	primary: 'text-base-100',
	danger: 'text-[#FFE818]',
	lightBlue: 'text-base-100',
	secondary: 'text-base-100',
};

interface IProductWrapperCard {
	extraBanner?: string;
	row?: number;
	extraCategory?: React.ReactNode;
	extraProduct?: any;
	color: TypeColors;
	title: string;
	isLoadMore?: boolean;
	isLoading?: boolean;
	isMobile?: boolean;
	typeProduct: keyof typeof TYPE_PRODUCT;
	onLoadMore?: () => void;
}

const ProductWrapperCard: React.FC<IProductWrapperCard> = ({
	extraBanner,
	row,
	title,
	color,
	isLoading,
	isLoadMore,
	isMobile,
	extraProduct,
	typeProduct = TYPE_PRODUCT.NEW,
	extraCategory,
}) => {
	const swiperRef = useRef<SwiperRef>();
	const router = useRouter();
	const { addCart } = useCart();

	const sliderSettings = {
		320: {
			slidesPerView: 2,
			// spaceBetween: 20,
		},
		680: {
			slidesPerView: 2,
			// spaceBetween: 20,
		},
		1024: {
			slidesPerView: 5,
			// spaceBetween: 20,
		},
	};

	const memoExtraProduct = useMemo(() => {
		if (typeProduct === TYPE_PRODUCT.DISCOUNT) {
			return extraProduct?.discount;
		}
		if (typeProduct === TYPE_PRODUCT.NEW) {
			return extraProduct?.new;
		}
		return extraProduct.popular;
	}, [extraProduct?.popular, extraProduct?.discount, extraProduct?.new]);

	return (
		<div
			className={classNames([
				'rounded-none lg:rounded-xl p-1 lg:p-medium overflow-hidden lg:my-[30px] relative',
				RenderColor[color],
			])}
		>
			{extraBanner ? (
				<>
					<div className='absolute w-full top-0 left-0 wrapper h-[120px]'>
						<img className='w-full' src={extraBanner} />
					</div>
					<div className={classNames('pb-[90px]')}></div>
				</>
			) : (
				<h3
					className={classNames([
						'font-bold flex justify-center lg:justify-center text-large mt-4 ml-2 lg:ml-0 lg:mt-0 lg:text-large-4x uppercase',
						RenderTitleColor[color],
					])}
				>
					{title}
				</h3>
			)}

			{extraCategory}

			{!isMobile && typeProduct === TYPE_PRODUCT.NEW ? (
				<div className={classNames('flex gap-6 mt-7', extraBanner && 'mt-[40px]')}>
					{[memoExtraProduct?.list?.[0], memoExtraProduct?.list?.[1]]?.map((m, index) => {
						const priceSplit = m?.variants
							.map((item: any) => {
								return {
									currentPrice: item.salePrice,
									split: item.revenueSplit,
								};
							})
							.sort((a: any, b: any) => a.currentPrice - b.currentPrice);

						return (
							<div
								className='flex flex-[0_0_50%] relative max-w-[calc(50%_-_12px)] max-h-[250px] overflow-hidden h-max bg-base-100 rounded-[5px]'
								key={index}
							>
								<IconAddCartNow data={m!} onClickCart={() => addCart(m?.id)} />
								<span
									onClick={() => router.push(`/product-detail/[slug]`, `/chi-tiet/${m?.id}`)}
									className='flex flex-col gap-2 flex-[0_0_60%] max-w-[calc(60%_-_12px)] p-[42px] cursor-pointer'
								>
									<div className={'flex items-center gap-1'}>
										<div className='text-red-custom text-large font-bold'>
											{priceSplit?.[0]?.currentPrice?.toLocaleString('it-IT')}₫
										</div>
										{priceSplit?.[0]?.split && priceSplit?.[0]?.split < 100 ? (
											<TagDiscount percent={100 - priceSplit?.[0]?.split} />
										) : null}
									</div>
									<h6 className='text-[##333333] text-large line-clamp-2 min-h-[60px] font-bold'>
										{m?.name}
									</h6>
									<div
										className='line-clamp-2 break-words mt-2'
										dangerouslySetInnerHTML={{ __html: m?.data?.description! }}
									></div>
								</span>
								<div className='flex-auto overflow-hidden'>
									<ImageCustom
										fill
										src={m?.images[0]!}
										className={'aspect-square !h-full group-hover:scale-110 animation-200'}
										alt={m?.data?.name}
										objectFit='contain'
									/>
								</div>
							</div>
						);
					})}
				</div>
			) : null}

			{!isMobile ? (
				<div className='relative'>
					{!isLoading ? (
						<button className='swiper-btn-prev' onClick={() => swiperRef.current?.slidePrev()}>
							<ChevronLeftIcon className='text-primary' width={24} />
						</button>
					) : null}

					<Swiper
						onBeforeInit={(swiper) => {
							swiperRef.current = swiper;
						}}
						style={{ height: 'auto', marginTop: 28 }}
						breakpoints={sliderSettings}
						grid={{
							rows: row ?? 1,
						}}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
						modules={[Grid, Autoplay]}
						className='mySwiper'
						loop={true}
					>
						{memoExtraProduct?.list?.length ? (
							<div className='custom_wrapper'>
								{memoExtraProduct?.list.map((slideContent: any, index: any) => {
									const { variants, images, name, id } = slideContent ?? {};

									const priceSplit = variants
										.map((item: any) => {
											return {
												currentPrice: item.salePrice,
												split: item.revenueSplit,
											};
										})
										.sort((a: any, b: any) => a.currentPrice - b.currentPrice);

									return (
										<SwiperSlide
											className={classNames(['slide-product p-[20px]'])}
											key={index}
											virtualIndex={index}
										>
											<div className='custom_item w-full h-full rounded overflow-hidden'>
												<ProductCard
													isCenter
													key={index}
													url={`/chi-tiet/${slideContent?.id}`}
													image={images?.[0] ?? ''}
													title={name}
													price={priceSplit[0]?.currentPrice}
													split={priceSplit[0] && priceSplit[0].split}
													onClick={() => addCart(id)}
												/>
											</div>
										</SwiperSlide>
									);
								})}
							</div>
						) : (
							<div className='grid grid-cols-5 gap-5'>
								{[...new Array(5)]?.map((k, i) => (
									<div key={i} className='slide-product bg-base-100'>
										<div className='w-full h-full'>
											<div className='w-full pb-4 aspect-square relative overflow-hidden'>
												<SkeletonImage />
											</div>
											<div className='space-y-[16px] text-left py-9 px-5'>
												<SkeletonLine />
											</div>
										</div>
									</div>
								))}
							</div>
						)}
					</Swiper>
					{!isLoading ? (
						<button className='swiper-btn-next' onClick={() => swiperRef.current?.slideNext()}>
							<ChevronRightIcon className='text-primary' width={24} />
						</button>
					) : null}
				</div>
			) : (
				<div className='relative mt-[20px]'>
					<div className='flex flex-col flex-wrap -m-[4px] overflow-x-auto h-[550px]'>
						{memoExtraProduct?.list?.length
							? memoExtraProduct.list?.map((slideContent: any, index: number) => {
									const { variants, images, name, id } = slideContent ?? {};

									const priceSplit = variants
										.map((item: any) => {
											return {
												currentPrice: item.salePrice,
												split: item.revenueSplit,
											};
										})
										.sort((a: any, b: any) => a.currentPrice - b.currentPrice);
									return (
										<div key={index} className='p-[4px] h-[50%] w-[38%]'>
											<ProductCard
												className='h-full'
												key={index}
												url={`/chi-tiet/${slideContent?.id}`}
												image={images?.[0] ?? ''}
												title={name}
												price={priceSplit[0]?.currentPrice}
												split={priceSplit[0] && priceSplit[0].split}
												onClick={() => addCart(id)}
											/>
										</div>
									);
							  })
							: [...new Array(6)]?.map((k, i) => (
									<div key={i} className='p-[8px] h-[50%] w-[42%]'>
										<div className='slide-product bg-base-100'>
											<div className='w-full h-full'>
												<div className='w-full pb-4 aspect-square relative overflow-hidden'>
													<SkeletonImage />
												</div>
												<div className='space-y-[16px] text-left py-9 px-5'>
													<SkeletonLine nonLineMain />
												</div>
											</div>
										</div>
									</div>
							  ))}
					</div>
				</div>
			)}

			{isLoadMore ? (
				<ButtonUI
					className='bg-base-100 w-full lg:w-max capitalize text-primary mx-auto flex justify-center text-base lg:text-medium hover:text-base-100 mt-4 mb-4 lg:mb-0'
					text='Xem tất cả sản phẩm'
					onClick={() => {
						router.push({
							pathname: '/san-pham',
							// query: {
							// 	typeProduct: typeProduct,
							// },
						});
					}}
				/>
			) : null}
		</div>
	);
};

export default React.memo(ProductWrapperCard);
