import { Notification } from '@/components';
import { MESSSAGE_GLOBAL } from '@/constant';
import { IParamsVariant, IVariantWithId, ParamsRequestSaga } from '@/models';
import { fetchListVariantWithId } from '@/services';
import { commonActions } from '@/store/reducers/commonSlice';
import { getErrorMessageInstance } from '@/utils';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

function* handleFetchVariantWithId(
	action: PayloadAction<ParamsRequestSaga<IParamsVariant, IVariantWithId['data']>>,
) {
	try {
		const resp: IVariantWithId = yield call(fetchListVariantWithId, action.payload.params);
		if (!resp?.isError) {
			action.payload?.onSuccess?.(resp?.data);
			yield put(commonActions.fetchListVariantWithIdSuccess(resp?.data));
		} else {
			yield put(commonActions.fetchListVariantWithIdFailed());
			Notification.Info.default(MESSSAGE_GLOBAL, 'ERROR', 3000);
		}
	} catch (error) {
		getErrorMessageInstance(error);
		yield put(commonActions.fetchListVariantWithIdFailed());
	}
}

export default function* commonSaga() {
	yield takeEvery(commonActions.fetchListVariantWithIdRequest.type, handleFetchVariantWithId);
}
