import { ButtonUI, ImageCustom } from '@/components';
import { useRouter } from 'next/router';

const EmptyCart = () => {
	const router = useRouter();
	return (
		<div className='flex px-2.5 lg:px-0 pt-4 lg:pt-0 flex-col items-center h-fit lg:pb-8 text-center justify-center place-items-center'>
			<div className='relative w-[200px] md:w-[300px] lg:w-[350px] aspect-square flex justify-center'>
				<ImageCustom
					unoptimized
					fill
					className='!h-full'
					src={'/static/images/icon_empty_cart.png'}
				/>
			</div>
			<p className='text-large lg:text-large-2x pt-2 lg:pt-0 font-bold uppercase text-primary'>
				Giỏ hàng của bạn đang trống
			</p>
			<p className='text-small lg:text-base'>có vẻ như bạn vẫn chưa đưa ra lựa chọn của mình ...</p>

			<span
				onClick={() => router.push('/')}
				className='cursor-pointer flex w-auto justify-center text-center mx-auto pt-4 pb-4 lg:pb-0'
			>
				<ButtonUI text='Thêm vào giỏ hàng ngay' color='primary' />
			</span>
		</div>
	);
};

export default EmptyCart;
