import { IProductItem } from '@/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
// import * as yup from 'yup';

interface IVariantSelected {
	key: number | string;
	value: number | string;
}

export const useFormVariants = (dataProduct: IProductItem) => {
	const [dataVariantSelected, setDataVariantSelected] = useState<IVariantSelected[]>([]);

	const productVariantSelected = useMemo(() => {
		return `${dataProduct?.id}${dataVariantSelected?.map((v, _) => v.value)?.join('')}`;
	}, [dataVariantSelected]);

	// const renderValuesWithIdVariant = (idProductVariant: string) => {
	// 	const valuesWithId = dataProduct?.variations?.find((el) => el?.code === idProductVariant);
	// 	return valuesWithId ?? dataProduct;
	// };

	// const itemSchema = dataProduct?.data?.tierVariations?.map(
	// 	(_, index) => `tiervariant_${index + 1}`,
	// );

	// const schemaVariant = yup.object().shape({
	// 	isActive: yup.bool().default(!dataVariantSelected?.length),
	// 	...itemSchema?.reduce(
	// 		(p, r) =>
	// 			(p = {
	// 				...p,
	// 				[r]: yup.number().when('isActive', {
	// 					is: (value: boolean) => (!value ? true : false),
	// 					then: yup.number().required('Vui lòng chọn phân loại hàng'),
	// 				}),
	// 			}),
	// 		{},
	// 	),
	// });

	const methods: UseFormReturn<any, any> = useForm<any>({
		mode: 'onChange',
		shouldFocusError: true,
		// resolver: yupResolver(schemaVariant),
	});

	const validVariantForms = methods.formState.isValid;

	return {
		methods,
		validVariantForms,
		setDataVariantSelected,
		dataVariantSelected,
		productVariantSelected,
		// renderValuesWithIdVariant,
	};
};
