/* eslint-disable simple-import-sort/imports */
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface IHeadProps extends DefaultSeoProps {
	active?: boolean;
}

const Head: FC<IHeadProps> = ({ active, ...rest }) => {
	const router = useRouter();

	const concatSeoDefault: DefaultSeoProps = {
		...rest,
		title:
			rest?.title ??
			'Nga Liêm là doanh nghiệp chuyên phân phối các sản phẩm gia dụng và công nông nghiệp',
		description:
			rest?.description ??
			'Mua sắm sản phẩm ở tất cả ngành hàng. Giá tốt & Miễn phí vận chuyển. Mua và bán online trong 30 giây.',
		canonical:
			rest?.canonical ??
			process.env.NEXT_PUBLIC_DOMAIN_URL + (router.asPath === '/' ? '' : router.asPath),
		openGraph: {
			...{
				type: 'website',
				locale: 'vi_VN',
				siteName: process.env.NEXT_PUBLIC_DOMAIN_URL + (router.asPath === '/' ? '' : router.asPath),
				title:
					'Nga Liêm là doanh nghiệp chuyên phân phối các sản phẩm gia dụng và công nông nghiệp',
				description:
					'Mua sắm sản phẩm ở tất cả ngành hàng. Giá tốt & Miễn phí vận chuyển. Mua và bán online trong 30 giây.',
				url: rest?.openGraph?.siteName ?? process.env.NEXT_PUBLIC_DOMAIN_URL,
			},
			...rest?.openGraph,
			// images: rest?.openGraph?.images?.length
			// 	? rest?.openGraph?.images
			// 	: [
			// 			{
			// 				url: 'https://cdn.nppngaliem.com/banner/Card.png',
			// 				type: 'png',
			// 				secureUrl: 'https://cdn.nppngaliem.com/banner/Card.png',
			// 			},
			// 	  ],
		},
	};
	return (
		<>
			<DefaultSeo {...concatSeoDefault} />
			<NextHead>
				<meta charSet='UTF-8' />
				x <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
				<meta name='mobile-web-app-capable' content='yes' />
				{/* <meta name='apple-mobile-web-app-title' content='vuivui.com' />
				<meta name='apple-mobile-web-app-status-bar-style' />
				<meta name='apple-mobile-web-app-capable' content='yes' /> */}
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='icon' href='/favicon.ico' />
				{/* <meta
					name='viewport'
					content='width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no, viewport-fit=cover'
				/> */}
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'
				/>
				<title>{active ? rest?.title : 'Nga Liêm'}</title>
			</NextHead>
		</>
	);
};

export default Head;
