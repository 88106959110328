import { ParamsRefreshToken } from '@/models';
import { request } from '@/utils';

export const fetchLoginGuest = (): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/login_guest',
		method: 'POST',
	});
};

export const fetchRefreshLoginGuest = (data: ParamsRefreshToken): Promise<any> => {
	return request({
		baseURL: process.env.NEXT_PUBLIC_DOMAIN_API_AUTH!,
		url: '/v1/refresh_token',
		method: 'POST',
		data,
	});
};
