import { ACCESS_TOKEN } from '@/constant';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

enum REQUEST_TIMEOUT {
	default = 300000,
}

// console.log(process.env.NEXT_PUBLIC_DOMAIN_API!);

export const request = axios.create({
	baseURL: process.env.NEXT_PUBLIC_DOMAIN_API!,
	timeout: REQUEST_TIMEOUT.default,
});

const InterceptorsRequest = async (config: InternalAxiosRequestConfig) => {
	config.headers['Content-Type'] = 'application/json';

	const access_token = Cookies.get(ACCESS_TOKEN);

	if (access_token) {
		config.headers.Authorization = `Bearer ${access_token}`;
	}
	return config;
};

const InterceptorsError = async (error: any) => {
	return Promise.reject(error);
};

const InterceptorResponse = (response: AxiosResponse) => {
	if (response && response.data) {
		return response.data;
	}

	return response;
};

request.interceptors.request.use(InterceptorsRequest, InterceptorsError);
request.interceptors.response.use(InterceptorResponse, InterceptorsError);
