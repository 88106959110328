import { Route } from 'models';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { commonProductBreadcrumSelectors } from '@/store/reducers/commonSlice';
import { useAppSelector } from './useAppSelector';

export const useRoutes = () => {
	const { pathname } = useRouter();
	const [breadcrumbs, setBreadcrumbs] = useState<Route[]>([]);
	const product = useAppSelector(commonProductBreadcrumSelectors);

	const dynamicProductSlug = useMemo(() => {
		return {
			name: `${product?.name}` || '',
			urlSlug: product?.urlSlug || '',
			categoryId: product?.categoryId || '',
		};
	}, [product]);

	const routerList: Route[] = [
		{
			path: '',
			key: 'home',
			breadcrumbs: { name: 'Trang chủ', urlSlug: '/' },
		},
		{
			path: '[slug]',
			key: 'slug',
			breadcrumbs: dynamicProductSlug,
		},
	];

	useEffect(() => {
		let routes: Route[] = [];
		let slugs: string[] = [];
		pathname.split('/').forEach((query) => {
			slugs.push(query);
		});
		slugs.forEach((slug) => {
			const route: Route | undefined = routerList.find((route: Route) => route.path === slug);
			if (route) routes.push(route);
		});
		setBreadcrumbs(routes);

		return () => {
			slugs = [];
			routes = [];
		};
	}, [pathname, product]);

	return { breadcrumbs };
};
