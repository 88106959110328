import React from 'react';
import classNames from 'classnames';
import { CheckBoxItem, CheckBoxProps } from './interface';

export interface RadioCheckboxProps<T extends CheckBoxItem> extends CheckBoxProps<T> {}

const RadioCheckbox = <T extends CheckBoxItem>({
	className,
	label,
	checked,
	value,
	onChange,
}: RadioCheckboxProps<T>) => {
	return (
		<label
			className={classNames([
				'relative cursor-pointer flex space-x-3 items-center font-normal',
				className,
			])}
		>
			<input
				type='radio'
				className={classNames('absolute top-0 left-0 hidden opacity-0')}
				value={value}
				checked={checked}
				onChange={onChange}
			/>
			<span
				className={classNames(
					'block w-4 h-4 border-[1px] border-solid border-primary rounded-full relative before:content-[""] before:block before:w-[7px] before:h-[7px] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full',
					checked && 'border-[2px] before:border-[2px] before:border-solid before:border-primary',
				)}
			></span>
			{typeof label == 'string' ? <span>{label}</span> : label}
		</label>
	);
};

export default RadioCheckbox;
