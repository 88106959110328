import React, { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { cartSelectors } from '@/store/reducers/cartSlice';
import { commonActions, commonSelectors } from '@/store/reducers/commonSlice';
import {
	Bars3Icon,
	MagnifyingGlassIcon,
	PhoneIcon,
	ShoppingCartIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCart } from '@/modules/shared/hooks';

const Header = () => {
	const { dataCart } = useAppSelector(cartSelectors);
	const { getCart } = useCart();
	const dispatch = useAppDispatch();
	const { dataCategoryTree } = useAppSelector(commonSelectors);
	const router = useRouter();
	const stateCategory = dataCategoryTree?.data?.data?.rows ?? [];
	const refSearch = useRef<HTMLInputElement>(null);
	const [quantity, setQuantity] = React.useState<number>(0);

	React.useEffect(() => {
		const cart = getCart();
		setQuantity(cart.length);

		window.addEventListener('storage', () => {
			const cart = getCart();
			setQuantity(cart.length);
		});

		return () => {
			window.removeEventListener('storage', () => {
				const cart = getCart();
				setQuantity(cart.length);
			});
		};
	}, []);

	const MENU_NAVBAR = [
		{
			link: '',
			title: 'Danh mục sản phẩm',
			isBreakwork: true,
			render: (
				<ul className='z-[200] absolute rounded-box text-base-content bg-base-100 p-[20px] w-[460px]'>
					<div className='flex flex-wrap -mx-4 -my-1'>
						{stateCategory?.length &&
							stateCategory?.map((el: any, i: any) => (
								<li key={i} className='px-4 py-3 w-1/2'>
									<Link
										href={`/san-pham?category=${el?.id}`}
										className={
											'text-[16px] p-0 text-[#1F549D] hover:bg-transparent hover:font-bold transition-all duration-100'
										}
									>
										{el?.name}
									</Link>
								</li>
							))}
					</div>
				</ul>
			),
		},
		{
			title: 'Giới thiệu',
			asLink: '/gioi-thieu',
			link: '/about-us',
		},
		// {
		// 	title: 'Tin tức',
		// 	asLink: '/tin-tuc',
		// 	link: '/blog',
		// },
	];
	return (
		<div className='bg-primary shadow-none'>
			<div className='2xl:container bg-inherit navbar flex flex-col lg:flex-row lg:justify-between text-[14px] lg:text-base lg:flex-none gap-3 lg:px-medium py-small'>
				<div className='navbar-start flex pb-4 lg:pb-0 justify-between w-full lg:w-auto shrink-0'>
					<Link href={'/'}>
						<img
							src={'/static/images/ngaliem_logo_white.svg'}
							alt='logo_ngaliem'
							className='object-contain h-full w-[150px] lg:w-[150px] lg:h-[48px]'
						/>
					</Link>
					<div className='flex gap-4 lg:hidden'>
						<Link href={'/cart'} as='/gio-hang' className='indicator text-base-100'>
							<ShoppingCartIcon width={20} />
							{quantity ? <span className='badge badge-sm indicator-item'>{quantity}</span> : null}
						</Link>
						<label
							htmlFor='menu-drawer'
							className='text-base-100'
							onClick={() => dispatch(commonActions.setOpenMenuDrawer(true))}
						>
							<Bars3Icon width={20} />
						</label>
					</div>

					<div className='hidden lg:flex'>
						<ul className='menu menu-horizontal gap-8 text-base-300 pl-12'>
							{MENU_NAVBAR?.map((el, index) =>
								!el?.render ? (
									<li key={index}>
										<Link
											as={el?.asLink}
											href={el?.link}
											className={classNames([
												'text-[16px] font-normal text-center p-0 bg-inherit',
												el?.isBreakwork && 'break-words',
											])}
										>
											{el?.title}
										</Link>
									</li>
								) : (
									<li tabIndex={0} key={index}>
										<span
											className={classNames([
												'text-[16px] font-normal w-max text-center p-0 bg-inherit',
												el?.isBreakwork && 'break-words',
											])}
										>
											{el?.title}
										</span>
										{el?.render}
									</li>
								),
							)}
						</ul>
					</div>
				</div>
				<div className='navbar-center justify-center items-center w-full max-w-[400px]'>
					<div className='form-control w-full'>
						<div className='flex items-center w-full relative'>
							<input
								type='text'
								placeholder='Bạn tìm kiếm gì ?'
								ref={refSearch}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										if (refSearch.current?.value) {
											router.push({
												pathname: '/san-pham',
												query: {
													ProductName: refSearch.current.value,
												},
											});
											refSearch.current.value = '';
										}
									}
								}}
								className='input input-bordered rounded-[5px] lg:rounded-large w-[calc(100vw_-_20px)] md:w-[300px] h-[40px] text-small lg:h-[48px] lg:w-max flex-auto'
							/>
							<MagnifyingGlassIcon
								width={20}
								onClick={() => {
									if (refSearch.current?.value) {
										router.push({
											pathname: '/san-pham',
											query: {
												ProductName: refSearch.current.value,
											},
										});
										refSearch.current.value = '';
									}
								}}
								className='text-primary cursor-pointer absolute top-[50%] right-[10px] -translate-x-[10px] -translate-y-[50%]'
							/>
						</div>
					</div>
				</div>
				<div className='navbar-end hidden md:gap-[30px] lg:gap-[50px] xl:gap-[80px] lg:flex flex-auto max-w-fit'>
					<a
						href='tel:0977998197'
						className='flex !bg-neutral/25 p-1 rounded-[5px] text-[16px] items-center gap-2 text-base-100'
					>
						<div className='flex gap-1 items-center'>
							<div className='flex items-center gap-1'>
								<PhoneIcon width={20} />
								<span>Gọi mua hàng</span>
							</div>
							<span>0977998197</span>
						</div>
					</a>
					<div className='flex items-center space-x-8'>
						{!quantity ? (
							<Link className='whitespace-nowrap text-[16px] text-base-100 px-1' href={'/gio-hang'}>
								Giỏ hàng
							</Link>
						) : (
							<div className='dropdown-end dropdown'>
								<label
									tabIndex={0}
									role={'presentation'}
									htmlFor='none'
									className='btn-ghost btn-circle btn w-fit'
								>
									<div className='indicator text-base-100'>
										<div className='whitespace-nowrap px-1 capitalize font-normal text-[16px]'>
											Giỏ hàng
										</div>
										{quantity ? (
											<span className='badge badge-sm indicator-item'>{quantity}</span>
										) : null}
									</div>
								</label>

								<div
									tabIndex={0}
									className='card dropdown-content card-compact mt-3 w-52 bg-base-100 shadow'
								>
									<div className='card-body'>
										<span className='text-lg font-bold'>{quantity} sản phẩm</span>
										<span className='text-info'>
											{/* Tạm tính:{dataCart?.totalPrice?.toLocaleString('it-IT')}₫ */}
										</span>
										<Link href={'/gio-hang'} className='card-actions'>
											<button className='btn-primary btn-block btn'>Xem giỏ hàng</button>
										</Link>
									</div>
								</div>
							</div>
						)}

						<Link
							href={'/check-order'}
							as={'/kiem-tra-don-hang'}
							className='text-base-100 w-max text-center text-[16px]'
						>
							Kiểm tra đơn hàng
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
