import { IVariantWithId } from '@/models';
import { commonActions } from '@/store/reducers/commonSlice';
import React from 'react';

interface IQuantityHelper {
	productId: string[];
	dispatch: React.Dispatch<any>;
	onSuccess?: (response: IVariantWithId) => void;
}

export const hanleGetVariantWithId = ({ dispatch, productId, onSuccess }: IQuantityHelper) => {
	return dispatch(
		commonActions.fetchListVariantWithIdRequest({
			params: { productId: productId },
			onSuccess: (res) => onSuccess?.(res!),
		}),
	);
};
